import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CenteredContentBox, BoxTitle, QRCodeImage } from './InviteQRCodePage.styles';
import { Box } from '@mui/material';
import { GreenButton } from '../../../common/styles/buttons.styles';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export default function QRCodeDownload({ qrCode }) {
    const { projectId } = useParams();
    const { t, i18n } = useTranslation(['home', 'main']);
    return (_jsxs(CenteredContentBox, { children: [_jsx(BoxTitle, { children: "QR Code" }), _jsx(Box, { sx: { my: 2 }, children: _jsx(QRCodeImage, { src: qrCode, alt: "QR Code" }) }), _jsx(GreenButton, { component: "a", href: qrCode, download: `memorable-${projectId}-qr-code.png`, disabled: !qrCode, children: t("commons.download", { ns: ['main'] }) })] }));
}
