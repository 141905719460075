import { styled } from '@mui/material/styles';
export const Video = styled('video', {
    shouldForwardProp: (prop) => prop !== "orientation",
})(({ orientation }) => ({
    background: "#000",
    maxWidth: "80vw",
    maxHeight: "60vh",
    height: "60vh",
    margin: "0 auto",
    ["@media (maxWidth:960px)"]: {
        ...(orientation === "paysage"
            ? {
                maxWidth: "100vw",
                // height: "fit-content",
            }
            : {}),
    },
    ["@media (maxWidth:600px)"]: {
        ...(orientation === "portrait"
            ? {
                width: "fit-content",
            }
            : {}),
    },
    aspectRatio: orientation === "paysage" ? "16/9" : "9/16",
}));
