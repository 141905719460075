import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useWatch } from 'react-hook-form';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useCookies } from 'react-cookie';
export default function StripePaymentElement({ control }) {
    const [cookies] = useCookies();
    const user = cookies.user;
    const firstName = useWatch({
        control,
        name: "firstName",
        defaultValue: ""
    });
    const lastName = useWatch({
        control,
        name: "lastName",
        defaultValue: ""
    });
    const address = useWatch({
        control,
        name: "address",
        defaultValue: ""
    });
    const zipCode = useWatch({
        control,
        name: "zipCode",
        defaultValue: ""
    });
    const city = useWatch({
        control,
        name: "city",
        defaultValue: ""
    });
    const country = useWatch({
        control,
        name: "country",
        defaultValue: ""
    });
    return (_jsx(PaymentElement, { id: "payment-element", options: {
            layout: "tabs",
            defaultValues: {
                billingDetails: {
                    name: `${firstName} ${lastName}`,
                    email: user.email,
                    address: {
                        line1: address,
                        postal_code: zipCode,
                        city: city,
                        state: country,
                    }
                }
            }
        } }));
}
