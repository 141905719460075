import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import useUser from '../../hooks/user';
import { routes } from '../../App';
/**
 * verify that user is an organizer that has a subscription payment type
 * Otherwise redirect to home page
 * @constructor
 */
export default function OrganizersPaymentTypeGuard({ allowedPaymentType }) {
    const { user, isContributor } = useUser();
    const { projectId } = useParams();
    // Redirect logged contributor trying to access organizer pages
    // Note: we use window.location.replace to prevent multiple redirect issue leading to wrong redirection issue
    if (!!user && isContributor()) {
        if (projectId) {
            window.location.replace(`/${routes.contributor}/${routes.messages}/${projectId}`);
        }
        else {
            window.location.replace(`/not-authorized`);
        }
    }
    const fallbackUrl = projectId ? `/${routes.user}/${routes.messages}/${projectId}` : "/";
    return !!user && user.payment_type === allowedPaymentType ? _jsx(Outlet, {}) : _jsx(Navigate, { to: fallbackUrl });
}
