import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { routes } from "../../App";
export default function ContributorsLoginRoutes() {
    const [cookies] = useCookies();
    const { projectId, isProfessional } = useParams();
    return Boolean(cookies.hasSeenContributorOnboarding)
        ? _jsx(Outlet, {})
        : _jsx(Navigate, { to: `/${routes.onboarding}/${projectId}/0/${isProfessional}` });
}
