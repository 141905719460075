import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Link as LinkIcon, QrCode2 } from '@mui/icons-material';
import { Alert, Box, Typography, IconButton, Snackbar, } from "@mui/material";
import { WhatsappShareButton, WhatsappIcon, TelegramIcon, TelegramShareButton } from 'react-share';
import { theme } from "../../../utils/theme";
import { isMobile } from '../../../utils/devices';
import { routes } from '../../../App';
import ShareProjectTooltip from '../../common/tooltips/ShareProjectTooltip';
// @ts-ignore
import share from "../../../../public/share.svg";
import { useTranslation } from "react-i18next";
export default function ShareButton({ project }) {
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const finaleVideoUrl = `${process.env.REACT_APP_BASE_URL}/${routes.messages}/${project.id}/video-finale`;
    const shareTitle = `Découvrez la vidéo collective !`;
    const shareDetails = { title: shareTitle, url: finaleVideoUrl, text: shareTitle };
    const { t, i18n } = useTranslation(['home', 'main']);
    function copyShareLink() {
        navigator.clipboard.writeText(finaleVideoUrl);
        setCopySuccess(true);
        handleTooltipClose();
    }
    function handleTooltipClose() {
        setTooltipOpen(false);
    }
    /**
     * Opens either:
     * - native share menu on mobile devices
     * - share tooltip on other devices
     */
    async function handleShare() {
        if (!!navigator.share && isMobile()) {
            try {
                await navigator.share(shareDetails);
            }
            catch (error) {
                console.error(`Le partage de la vidéo a échoué - ${error}.`);
            }
        }
        else {
            setTooltipOpen((open) => !open);
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(ShareProjectTooltip, { content: (_jsxs(Box, { sx: { display: "flex", flexDirection: "column-reverse", justifyContent: "middle" }, children: [_jsx(Box, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}/partager/qr-code`, textAlign: "center", children: _jsx(QrCode2, { fontSize: "large", color: "primary" }) }), _jsx(Box, { textAlign: "center", children: _jsx(LinkIcon, { fontSize: "large", color: "primary", sx: { cursor: 'pointer' }, onClick: copyShareLink }) }), _jsx(Box, { textAlign: "center", children: _jsx(WhatsappShareButton, { title: shareTitle, url: finaleVideoUrl, children: _jsx(WhatsappIcon, { size: 32, round: true }) }) }), _jsx(Box, { py: 1, textAlign: "center", children: _jsx(TelegramShareButton, { title: shareTitle, url: finaleVideoUrl, children: _jsx(TelegramIcon, { size: 32, round: true }) }) })] })), open: isTooltipOpen, onClose: handleTooltipClose, children: _jsxs(Box, { textAlign: "center", width: 100, children: [_jsx(IconButton, { sx: { height: "fit-content", mb: 1, p: 0 }, onClick: handleShare, children: _jsx("img", { src: share, alt: "share", style: {
                                    width: theme.typography.pxToRem(35),
                                    height: theme.typography.pxToRem(35),
                                    color: theme.palette.primary.main,
                                } }) }), _jsx(Typography, { fontSize: 12, children: t("commons.share", { ns: ['main'] }) })] }) }), _jsx(Snackbar, { anchorOrigin: { vertical: "top", horizontal: "center" }, open: copySuccess, onClose: () => setCopySuccess(false), autoHideDuration: 4000, children: _jsx(Alert, { severity: "success", children: t("commons.link_copied", { ns: ['main'] }) }) })] }));
}
