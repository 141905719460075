import { format } from 'date-fns';
const priceFormater = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
});
export function formatPrice(price) {
    return priceFormater.format(price);
}
export function formatDuration(seconds) {
    const formatStr = seconds < 60 ? "s','S 'sec'" : seconds < 3600 ? "m','ss'min'" : "H'h'mm";
    return format(new Date(seconds * 1000), formatStr);
}
