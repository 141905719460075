import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { SecondaryWhiteButton, WhiteSecondaryButton } from '../common/styles/buttons.styles';
// @ts-ignore
import desktopBgSrc from './assets/desktop-default-background.png';
// @ts-ignore
import desktopStep0BgSrc from './assets/step-0-desktop-background.jpg';
// @ts-ignore
import desktopStep1BgSrc from './assets/step-1-desktop-background.png';
// @ts-ignore
import desktopStep2BgSrc from './assets/step-2-desktop-background.png';
// @ts-ignore
import mobileWelcomeBgSrc from './assets/step-0-mobile-background.jpg';
// @ts-ignore
import mobileBgSrc from './assets/step-1-2-mobile-background.png';
const TITLE_SIZE = 24;
const TEXT_SIZE = 16;
function getDesktopStepBackground(step) {
    switch (step) {
        case "0":
            return `url(${desktopStep0BgSrc})`;
        case "1":
            return `url(${desktopStep1BgSrc})`;
        case "2":
            return `url(${desktopStep2BgSrc})`;
        default:
            return `url(${desktopBgSrc})`;
    }
}
function getMobileStepBackground(step) {
    switch (step) {
        case "0":
            return `url(${mobileWelcomeBgSrc})`;
        case "1":
        case "2":
        default:
            return `url(${mobileBgSrc})`;
    }
}
export const OnboardingWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "step",
})(({ step, theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100dvw",
    height: "100dvh",
    position: "relative",
    background: getDesktopStepBackground(step),
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
        background: getMobileStepBackground(step),
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
    }
}));
export const OnboardingWrapperPro = styled(Box, {
    shouldForwardProp: (prop) => prop !== "step",
})(({ step, theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100dvh",
    position: "relative",
    backgroundColor: '#F9F1EC',
    // background: getDesktopStepBackground(step),
    // backgroundSize: "cover",
    // backgroundPosition: "center center",
    // backgroundRepeat: "no-repeat",
    // [theme.breakpoints.down("sm")]: {
    //   background: getMobileStepBackground(step),
    //   backgroundSize: "cover",
    //   backgroundPosition: "center center",
    //   backgroundRepeat: "no-repeat",
    // }
}));
export const OnboardingTitlePro = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    width: "90%",
    paddingTop: theme.spacing(1.2),
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(TITLE_SIZE),
    fontFamily: "var(--main_font_family_secondary)",
    fontStyle: "italic",
}));
export const OnboardingContainerPro = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0, "auto"),
    [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(0, "auto"),
    }
}));
export const OnboardingContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(9, 6, 8, 6),
    [theme.breakpoints.up("sm")]: {
        width: "500px",
        justifyContent: "center",
        margin: theme.spacing(0, "auto"),
        padding: theme.spacing(9, 8, 8, 8),
    }
}));
export const OnboardingSkipButton = styled(WhiteSecondaryButton)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: "8px",
    position: "absolute",
    right: "1Opx",
    top: "20px",
}));
export const OnboardingNextButtonPro = styled(SecondaryWhiteButton)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
}));
export const OnboardingNextButton = styled(SecondaryWhiteButton)(({ theme }) => ({
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
}));
export const OnboardingLogo = styled('img')(({ theme }) => ({
    width: "auto",
    maxWidth: "100%",
    maxHeight: "80px",
    margin: theme.spacing(2, "auto", 0, "auto"),
    [theme.breakpoints.up("sm")]: {
        position: "absolute",
        top: "50px",
        left: 0,
        right: 0,
    }
}));
export const OnboardingLogoPro = styled('img')(({ theme }) => ({
    width: "auto",
    margin: theme.spacing(2, "auto", 0, "auto"),
    marginTop: 25,
}));
export const OnboardingTitle = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(TITLE_SIZE),
    fontFamily: "var(--main_font_family_secondary)",
    fontStyle: "italic",
}));
export const OnboardingText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(TEXT_SIZE),
}));
export const OnboardingStepsContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "7px",
    display: "flex",
    columnGap: "10px",
}));
export const OnboardingStep = styled(Box, {
    shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    opacity: active ? 1 : 0.5,
}));
export const OnboardingStepNumber = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70px",
    height: "70px",
    color: "#fff",
    border: "solid 2px #fff",
    borderRadius: "50%",
    fontSize: "var(--font_size_title)",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));
export const OnboardingImage = styled('img')(({ theme }) => ({
    maxWidth: "100%",
    // height: "250px",
    width: "auto",
    maxHeight: "50vh",
    [theme.breakpoints.up("sm")]: {
        display: "none",
    },
}));
