import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ShareProjectTooltipContainer } from './Tooltip.styles';
export default function ShareProjectTooltip({ content, children, open, onClose }) {
    return (_jsx(ShareProjectTooltipContainer, { open: open, onClose: onClose, title: content, arrow: true, placement: "top", enterTouchDelay: 0, disableHoverListener: true, disableFocusListener: false, disableTouchListener: true, PopperProps: {
            onPointerDown: e => {
                if (e.target instanceof Element &&
                    !e.target.classList.contains("MuiTooltip-tooltip")) {
                    e.preventDefault();
                }
            },
        }, children: children }));
}
