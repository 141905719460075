import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
export const ActionsWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    alignItems: "center",
    backgroundColor: theme.palette.backgrounds.login,
    width: "100%",
    left: 0,
    height: 50,
    padding: `${theme.spacing(0, 0, 0)} !important`,
    bottom: 85
}));
export const ActionsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: "center",
}));
