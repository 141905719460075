import axios from 'axios';
export const PROJECT_MUSIC_SELECTION_ID = "project-music-selection";
export const MUSIC_DEFAULT_VOLUME = 4;
export const MUSIC_DEFAULT_VALUE = {
    volume: MUSIC_DEFAULT_VOLUME,
    files: [],
    loop: false,
};
export const MUSIC_LIBRARY = [
    {
        id: "party",
        file_name: "Party",
        file_path: "https://res.cloudinary.com/deiusslow/video/upload/v1690983045/musics/party_song.mp3",
        cover: "https://res.cloudinary.com/deiusslow/image/upload/v1690983072/musics/party_cover.png",
        duration: 120,
    },
    {
        id: "love",
        file_name: "Love",
        file_path: "https://res.cloudinary.com/deiusslow/video/upload/v1690983045/musics/love_song.mp3",
        cover: "https://res.cloudinary.com/deiusslow/image/upload/v1690983072/musics/love_cover.png",
        duration: 195,
    },
    {
        id: "ride",
        file_name: "Ride",
        file_path: "https://res.cloudinary.com/deiusslow/video/upload/v1690983045/musics/ride_song.mp3",
        cover: "https://res.cloudinary.com/deiusslow/image/upload/v1690983073/musics/ride_cover.png",
        duration: 124,
    },
    {
        id: "dream",
        file_name: "Dream",
        file_path: "https://res.cloudinary.com/deiusslow/video/upload/v1690983045/musics/dream_song.mp3",
        cover: "https://res.cloudinary.com/deiusslow/image/upload/v1690983072/musics/dream_cover.png",
        duration: 110,
    },
];
export const MUSIC_LIBRARY_IDS = MUSIC_LIBRARY.map((item) => item.id);
export const MUSIC_PREVIEW_DURATION = 15000; // 15 seconds
export function formatAudioDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const extraSeconds = Math.round(seconds % 60);
    return `${minutes}:${extraSeconds < 10 ? `0${extraSeconds}` : extraSeconds}`;
}
export async function getAudioFileDuration(file) {
    return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const audio = document.createElement("audio");
        audio.preload = "metadata";
        audio.addEventListener("loadedmetadata", () => {
            const duration = audio.duration;
            resolve(audio.duration);
        });
        audio.src = url;
    });
}
export async function uploadCloudinaryAudioFile(file) {
    const cloudinaryFormData = new FormData();
    cloudinaryFormData.append("file", file);
    cloudinaryFormData.append("upload_preset", process.env.UPLOAD_PRESET || "");
    const response = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.CLOUD_NAME}/raw/upload`, cloudinaryFormData);
    return response?.data.url;
}
