import { jsx as _jsx } from "react/jsx-runtime";
import usePasswordVisibility from '../../hooks/password-visibility';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoginTextField } from './styles/Login.styles';
import React from 'react';
export default function PasswordField({ label, value, onChange }) {
    const { isPasswordVisible, togglePasswordVisibility, handleMouseDownPassword } = usePasswordVisibility();
    return (_jsx(LoginTextField, { label: label, variant: "standard", type: isPasswordVisible ? "text" : "password", autoComplete: "off", InputProps: {
            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: togglePasswordVisibility, onMouseDown: handleMouseDownPassword, size: "small", children: isPasswordVisible ? _jsx(VisibilityOff, { fontSize: "inherit" }) : _jsx(Visibility, { fontSize: "inherit" }) }) }))
        }, value: value, onChange: onChange }));
}
