import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import history from "history/browser";
import { useTranslation } from 'react-i18next';
import { Box, Container, Slider, Typography, useTheme } from '@mui/material';
import { Save, VolumeDown, VolumeUp } from '@mui/icons-material';
import GenericModal from "../GenericModal";
const DEFAULT_VOLUME = 5;
export default function VolumeField({ volume, disabled, onChange, onChangeCommitted }) {
    const { t } = useTranslation(['main']);
    const [currentVolume, setCurrentVolume] = useState(volume);
    const [hasChange, setHasChange] = useState(false);
    const [pathToGo, setPathToGo] = useState("/organisateur/message");
    const [closeWithoutSaveModal, setCloseWithoutSaveModal] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    let unblock = () => { };
    useEffect(() => {
        if (typeof volume !== 'number') {
            onChange(DEFAULT_VOLUME);
        }
    }, [volume]);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Perform actions before the component unloads
            event.preventDefault();
            event.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    useEffect(() => {
        if (!hasChange && unblock != null) {
            unblock();
        }
        unblock = history.block((tx) => {
            setPathToGo(tx.location.pathname);
            return manageBlockingSave(tx.location.pathname);
        });
        return () => {
            unblock();
        };
    }, [hasChange]);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    function manageBlockingSave(pathname) {
        if (hasChange) {
            setCloseWithoutSaveModal(true);
        }
        else {
            navigate(pathname);
        }
    }
    if (typeof volume !== 'number') {
        return null;
    }
    return (_jsxs(Box, { display: "flex", py: 2, flexDirection: "row", alignItems: "center", width: "100%", sx: {
            backgroundColor: theme.palette.backgrounds.login,
            position: 'absolute',
            left: 0,
            height: 50,
            padding: `${theme.spacing(0, 0, 0)} !important`,
            bottom: 85
        }, children: [volume <= 2.5 ? (_jsx(VolumeDown, { sx: { marginLeft: 5 }, color: (disabled || volume === 0) ? "disabled" : "secondary" })) : (_jsx(VolumeUp, { sx: { marginLeft: 5 }, color: disabled ? "disabled" : "secondary" })), _jsx(Typography, { sx: { color: theme.palette.secondary.main, px: 2 }, children: "0" }), _jsx(Slider, { size: "small", valueLabelDisplay: "auto", className: volume === 0 ? "Mui-disabled zero" : "", color: "secondary", min: 0, max: 5, step: 1, defaultValue: DEFAULT_VOLUME, value: volume, disabled: disabled, onChange: (_, value) => {
                    setHasChange(true);
                    onChange(value);
                    setCurrentVolume(value);
                }, sx: {
                    width: "90%",
                    ml: 1,
                    cursor: disabled ? 'default' : 'pointer !important',
                    pointerEvents: disabled ? 'none' : 'auto !important',
                } }), _jsx(Typography, { sx: { color: theme.palette.secondary.main, px: 2 }, children: "5" }), _jsx(Container, { sx: {
                    width: 50,
                    height: 50,
                    borderRadius: "8px",
                    background: hasChange ? theme.palette.secondary.main : 'lightgray',
                    color: theme.palette.common.white,
                    border: "none",
                    outline: "none",
                    cursor: hasChange ? "pointer" : "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "background 0.2s ease-in-out",
                    ["&:hover"]: {
                        background: hasChange ? theme.palette.primary.main : 'lightgray',
                    }
                }, onClick: () => {
                    if (hasChange) {
                        setHasChange(false);
                        onChangeCommitted(currentVolume);
                    }
                }, children: _jsx(Save, { sx: { fontSize: 20, color: "white" } }) }), _jsx(GenericModal, { title: t("commons.unsaved_changes", { ns: ['main'] }), open: closeWithoutSaveModal, onConfirm: async () => {
                    if (hasChange) {
                        await onChangeCommitted(currentVolume);
                        setHasChange(false);
                    }
                    setCloseWithoutSaveModal(false);
                    navigate(pathToGo);
                }, onCancel: () => {
                    setCloseWithoutSaveModal(false);
                    navigate(pathToGo);
                } })] }));
}
