import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useApiRequest from '../hooks/api-request';
const projectContextDefaultValue = {
    project: undefined,
    isProjectLoading: true,
    updateProject: () => null,
};
const ProjectContext = createContext(projectContextDefaultValue);
export function ProjectProvider({ children }) {
    const { projectId } = useParams();
    const API = useApiRequest();
    const [isProjectLoading, setProjectLoading] = useState(true);
    const [project, setProject] = useState();
    useEffect(() => {
        (async () => {
            if (projectId && project?.id !== projectId) {
                try {
                    setProjectLoading(true);
                    const response = await API.getProject(projectId);
                    setProject(response.data.project);
                }
                catch (e) {
                    console.log('Cannot get project', e);
                }
                finally {
                    setProjectLoading(false);
                }
            }
        })();
    }, [projectId]);
    function updateProject(updates) {
        const updatedProject = {
            ...project,
            ...updates,
        };
        setProject(updatedProject);
    }
    return (_jsx(ProjectContext.Provider, { value: {
            project,
            isProjectLoading,
            updateProject,
        }, children: children }));
}
export function ProjectProviderRoute() {
    return (_jsx(ProjectProvider, { children: _jsx(Outlet, {}) }));
}
export function useProject() {
    return useContext(ProjectContext);
}
