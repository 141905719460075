import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useTheme } from '@mui/material';
import { Outlet } from "react-router-dom";
import { LayoutContent, LayoutWrapper } from '../../common/styles/layouts.styles';
import SocialLinksFooter from '../../common/SocialLinksFooter';
export default function ContributorThanksLayout() {
    const theme = useTheme();
    return (_jsx(LayoutWrapper, { children: _jsxs(Box, { component: "main", sx: {
                flex: 1,
                overflow: "auto",
                height: "fill-available",
                display: "flex",
                flexDirection: "column",
                background: theme.palette.backgrounds.login,
            }, children: [_jsx(LayoutContent, { sx: { flex: 1 }, children: _jsx(Outlet, {}) }), _jsx(SocialLinksFooter, {})] }) }));
}
