import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
export default function MediaThumbnail({ type, url }) {
    const [thumbnail, setThumbnail] = useState(null);
    useEffect(() => {
        if (!thumbnail) {
            (async () => {
                if (type === 'image') {
                    setThumbnail(url);
                }
                if (type === 'video') {
                    const urlParts = url.split('/');
                    const fileParts = urlParts[urlParts.length - 1].split('.');
                    const fileExt = fileParts[fileParts.length - 1];
                    const regexp = new RegExp(`.${fileExt}$`);
                    const videoThumbnailUrl = url.replace(regexp, '.jpg');
                    setThumbnail(videoThumbnailUrl);
                }
            })();
        }
    }, [type, url, thumbnail]);
    return (_jsx(Box, { sx: {
            display: "inline-block",
            width: '42px',
            height: '42px',
            mr: 1.5,
            ml: 0.5,
            borderRadius: "100%",
            backgroundColor: 'grey',
            backgroundImage: `url(${thumbnail})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        } }));
}
