/**
 * Convert pixels to percentage value
 * @param pixels
 * @param max
 * @returns number
 */
export function pxToPercentage(pixels, max) {
    return pixels > 0 ? (pixels * 100) / max : 0;
}
/**
 * Convert percentage to pixels value
 * @param percentage
 * @param max
 * @returns number
 */
export function percentageToPx(percentage, max) {
    return percentage > 0 ? (percentage / 100) * max : 0;
}
