import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, IconButton, Table, TableBody, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { pluralize } from '../../../utils/strings';
import { CartSummaryBoldCell, CartSummaryCell, CartSummaryContainer, CartSummaryGreenCell, CartSummaryRow } from './CartSummaryTable.styles';
import { formatPrice } from '../../../utils/numbers';
import { GreenButton } from '../../common/styles/buttons.styles';
import useApiRequest from '../../../hooks/api-request';
import { useTranslation } from "react-i18next";
export default function CartSummaryTable({ contributorsCount, price, coupon, onCouponValidate, onCouponDelete, onCouponError }) {
    const API = useApiRequest();
    const [couponCode, setCouponCode] = useState('');
    const [totalPrice, setTotalPrice] = useState(price);
    const { t, i18n } = useTranslation(['home', 'main']);
    useEffect(() => {
        if (coupon?.percent_off) {
            setTotalPrice(price - (price * coupon.percent_off / 100));
        }
        else if (coupon?.amount_off) {
            setTotalPrice(price - coupon.amount_off);
        }
        else {
            setTotalPrice(price);
        }
    }, [price, coupon]);
    function handleCouponCodeChange(event) {
        setCouponCode(event.target.value);
    }
    async function verifyCoupon() {
        try {
            onCouponError(false);
            const response = await API.verifyStripeCoupon(couponCode);
            onCouponValidate(response.data.coupon);
        }
        catch (e) {
            onCouponError(true);
        }
        finally {
            setCouponCode('');
        }
    }
    return (_jsx(CartSummaryContainer, { children: _jsx(Table, { children: _jsxs(TableBody, { children: [_jsxs(CartSummaryRow, { children: [_jsxs(CartSummaryCell, { align: "left", children: [contributorsCount, " ", pluralize(contributorsCount, t("commons.participant", { ns: ['main'] }))] }), _jsx(CartSummaryCell, { align: "right", children: formatPrice(price) })] }), coupon ? (_jsxs(CartSummaryRow, { children: [_jsx(CartSummaryGreenCell, { align: "left", color: "secondary", children: _jsxs(Box, { display: "flex", alignItems: "center", children: [_jsxs("span", { children: [t("commons.promo_code", { ns: ['main'] }), ": ", coupon.id] }), _jsx(IconButton, { size: "small", sx: { ml: 0.5 }, onClick: onCouponDelete, children: _jsx(Clear, { fontSize: "small" }) })] }) }), _jsx(CartSummaryGreenCell, { align: "right", children: coupon.percent_off ? `-${coupon.percent_off}%` : `-${formatPrice(coupon.amount_off)}` })] })) : (_jsxs(CartSummaryRow, { children: [_jsx(CartSummaryCell, { align: "left", children: _jsx(TextField, { size: "small", label: t("commons.promo_code", { ns: ['main'] }), value: couponCode, onChange: handleCouponCodeChange }) }), _jsx(CartSummaryCell, { align: "right", children: _jsx(GreenButton, { disabled: !couponCode.length, onClick: verifyCoupon, children: t("commons.apply", { ns: ['main'] }) }) })] })), _jsxs(CartSummaryRow, { children: [_jsx(CartSummaryBoldCell, { align: "left", children: "Total" }), _jsx(CartSummaryBoldCell, { align: "right", children: formatPrice(totalPrice) })] })] }) }) }));
}
