import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Box, useTheme } from '@mui/material';
// @ts-ignore
import musicNote from "../../../../../../../public/music_note.svg";
export function ProjectMusicFileListItemCover({ cover }) {
    const theme = useTheme();
    return (_jsx(Box, { sx: {
            display: "inline-block",
            width: '42px',
            height: '42px',
            mr: 0.5,
            ml: 0.5,
            borderRadius: "100%",
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `url(${cover || musicNote})`,
            backgroundPosition: 'center center',
            backgroundSize: cover ? 'cover' : "20px",
            backgroundRepeat: 'no-repeat',
        } }));
}
