import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
export const BlockBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
    textAlign: "left",
    borderRadius: "8px",
}));
export const BlockBoxTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontStyle: "italic",
    fontSize: "1.2rem",
    color: theme.palette.primary.main,
    textAlign: "left",
}));
export const BlockBoxText = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
}));
