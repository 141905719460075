import { useState, useEffect } from 'react';
export default function useAudioPlayer() {
    const [audioNode] = useState(new Audio());
    const [isAudioPlaying, setAudioPlaying] = useState(false);
    useEffect(() => {
        if (audioNode) {
            function endListener() {
                audioNode.pause();
                audioNode.src = '';
                setAudioPlaying(false);
            }
            audioNode.addEventListener('ended', endListener);
            return () => {
                audioNode.removeEventListener('ended', endListener);
            };
        }
    }, [audioNode]);
    /**
     * Stop audio on unmount
     */
    useEffect(() => {
        return () => {
            destroyAudio();
        };
    }, []);
    async function createAudio(url, play) {
        if (url) {
            destroyAudio();
            audioNode.src = url;
            audioNode.load();
            if (play) {
                await playAudio();
            }
        }
    }
    function destroyAudio() {
        if (audioNode) {
            audioNode.pause();
            audioNode.src = '';
            setAudioPlaying(false);
        }
    }
    async function playAudio() {
        setAudioPlaying(true);
        await audioNode.play();
    }
    async function pauseAudio() {
        setAudioPlaying(false);
        await audioNode.pause();
    }
    function setAudioVolume(volume) {
        audioNode.volume = volume ? volume / 10 : 0;
    }
    return { audioNode, createAudio, destroyAudio, playAudio, pauseAudio, setAudioVolume, isAudioPlaying };
}
