export const LANDSCAPE_WIDTH = "600px";
export const LANDSCAPE_MOBILE_WIDTH = "80vw";
export const PORTRAIT_HEIGHT = "60vh";
export function getMediaPreviewCommonSx(orientation) {
    return {
        background: "#000",
        maxWidth: "80vw",
        maxHeight: "60vh",
        aspectRatio: orientation === "paysage" ? "16/9" : "9/16",
        ...(orientation === "paysage"
            ? { width: LANDSCAPE_WIDTH }
            : { height: PORTRAIT_HEIGHT }),
        margin: "0 auto",
        ["@media (max-width:960px)"]: {
            ...(orientation === "paysage"
                ? {
                    width: LANDSCAPE_MOBILE_WIDTH,
                }
                : {}),
        },
        ["@media (max-width:600px)"]: {
            ...(orientation === "portrait"
                ? {
                    width: "fit-content",
                }
                : {}),
        },
    };
}
