import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material';
import { Download, Edit, SwapVert, Visibility } from '@mui/icons-material';
import useUser from '../../../../../../hooks/user';
import { InstructionsList, InstructionsListItem } from './ProjectMediaInstructions.styles';
const iconSx = {
    color: "rgba(0, 0, 0, 0.12)",
};
export default function ProjectMediaInstructions({ readonly }) {
    const { t, i18n } = useTranslation(['home', 'main']);
    const { isSubscriptionBasedOrganizer } = useUser();
    return (_jsxs(InstructionsList, { children: [readonly && (_jsxs(InstructionsListItem, { children: [_jsx(Visibility, { sx: iconSx }), _jsx(Typography, { children: t("commons.see_content", { ns: ['main'] }) })] })), (readonly || isSubscriptionBasedOrganizer()) && (_jsxs(InstructionsListItem, { children: [_jsx(Download, { sx: iconSx }), _jsx(Typography, { children: t("commons.download_content", { ns: ['main'] }) })] })), !readonly && (_jsxs(_Fragment, { children: [_jsxs(InstructionsListItem, { children: [_jsx(Edit, { sx: iconSx }), _jsx(Typography, { children: t("dashboard_projects.add_text_etc", { ns: ['main'] }) })] }), _jsxs(InstructionsListItem, { children: [_jsx(SwapVert, { sx: iconSx }), _jsx(Typography, { children: t("dashboard_projects.drag_n_drop", { ns: ['main'] }) })] })] }))] }));
}
