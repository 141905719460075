import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Page, PageTitle } from '../../../common/styles/page.styles';
import { useNavigate, useParams } from 'react-router-dom';
import useApiRequest from '../../../../hooks/api-request';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useProject } from '../../../../contexts/project';
import { routes } from '../../../../App';
import VolumeField from '../../../common/VolumeField/VolumeField';
import CreatomateMediaPreview from '../../../common/CreatomateMediaPreview/CreatomateMediaPreview';
import { ActionsWrapper, ActionsContainer } from './MediaEditVolumePage.styles';
let prevMedia;
export default function MediaEditVolumePage() {
    const theme = useTheme();
    const { projectId, mediaId } = useParams();
    const navigate = useNavigate();
    const { project, isProjectLoading } = useProject();
    const API = useApiRequest();
    const [media, setMedia] = useState();
    const [mediaVolume, setMediaVolume] = useState();
    useEffect(() => {
        (async () => {
            if (project && mediaId) {
                try {
                    if (project.files) {
                        const media = project.files.find((media) => media.id === mediaId);
                        if (media.type !== "video") {
                            navigate(`/${routes.user}/${routes.messages}/${project.id}/edit/${media.id}`);
                        }
                        prevMedia = media;
                        setMedia(media);
                        setMediaVolume(media.volume);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [project, mediaId]);
    async function saveProjectMedia() {
        if (projectId && mediaId && media && media?.volume !== prevMedia?.volume) {
            try {
                await API.updateProjectFile(projectId, mediaId, media);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    function updateMediaVolume(volume) {
        if (media) {
            setMedia({ ...media, volume });
            setMediaVolume(volume);
        }
    }
    if (isProjectLoading || !project || !media) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { sx: { display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }, children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(PageTitle, { children: media.userName }), _jsx(CreatomateMediaPreview, { media: media, orientation: project.orientation })] }), _jsx(ActionsWrapper, { children: _jsx(ActionsContainer, { children: _jsx(VolumeField, { disabled: false, volume: mediaVolume, onChange: updateMediaVolume, onChangeCommitted: saveProjectMedia }) }) })] }));
}
