import { styled } from "@mui/material/styles";
import { Container, Typography } from '@mui/material';
// @ts-ignore
import backgroundUrl from "../../../public/memorable_m_white.svg";
export const PageWrapper = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#F9F1EC",
    backgroundImage: `url(${backgroundUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    backgroundSize: "70%",
    marginTop: 0,
    height: "100vh",
    width: "100vw",
    padding: theme.spacing(0, 4),
}));
export const Message = styled(Typography)(({ theme }) => ({
    // fontFamily: "var(--main_font_family_primary)",
    fontFamily: "var(--main_font_family_secondary)",
    fontSize: theme.spacing(3),
    fontWeight: 400,
    fontStyle: "italic",
    marginTop: 0,
    marginBottom: theme.spacing(3),
}));
