import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Page, PageTitle } from '../../common/styles/page.styles';
import { ProjectStatus } from '../../../models/project';
import useApiRequest from '../../../hooks/api-request';
import { Box, CircularProgress, Container } from '@mui/material';
import { theme } from '../../../utils/theme';
import FinalVideoLoadingModal from './FinalVideoLoadingModal';
import { ProjectVideo } from './ProjectVideo';
import DownloadButton from './DownloadButton';
import ShareButton from './ShareButton';
export default function FinalVideoPublicPage() {
    const API = useApiRequest();
    const { projectId } = useParams();
    const [project, setProject] = useState();
    useEffect(() => {
        (async () => {
            if (projectId) {
                try {
                    const { data } = await API.getProjectPublicData(projectId);
                    setProject(data.project);
                }
                catch (error) {
                    console.log('Error white loading project public data', error);
                }
            }
        })();
    }, [projectId]);
    function handleModalClose() {
        window.close();
    }
    if (!project) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { sx: { padding: "0 !important" }, children: [_jsx(PageTitle, { children: project.name }), _jsxs(Container, { sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }, children: [_jsx(Box, { sx: { my: 2, width: '100%', textAlign: "center" }, children: project?.finalVideo && _jsx(ProjectVideo, { project: project }) }), _jsxs(Box, { sx: { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "middle" }, children: [_jsx(DownloadButton, { project: project }), _jsx(ShareButton, { project: project })] })] }), _jsx(FinalVideoLoadingModal, { open: project.status !== ProjectStatus.COMPLETED, onClose: handleModalClose })] }));
}
