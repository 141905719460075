import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Alert, Box } from '@mui/material';
import { Stack } from "@mui/system";
import { LoginLogo, LoginPageWrapper, LoginTitle, } from './styles/Login.styles';
import { useFormInput } from "../../utils/FormInput";
import useApiRequest from '../../hooks/api-request';
import { OrangeButton, SecondaryButton } from '../common/styles/buttons.styles';
import useUrlSearchParams from '../../hooks/url-search-params';
import PasswordField from './PasswordField';
import { getRequestErrorMessage } from '../../utils/errors';
// @ts-ignore
import logo_black from "../../../public/logo_black.svg";
export default function PasswordResetPage() {
    const params = useUrlSearchParams();
    const password = useFormInput("");
    const passwordVerification = useFormInput("");
    const API = useApiRequest();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [tokenError, setTokenError] = useState();
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                const token = params.get("token");
                if (token) {
                    await API.organizerPasswordResetVerify(token);
                }
                else {
                    setTokenError("Le jeton de réinitialisation du mot de passe n'est pas valide.");
                }
            }
            catch (error) {
                const message = getRequestErrorMessage(error);
                setTokenError(message);
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);
    async function handlePasswordReset() {
        try {
            setError("");
            setSuccess(false);
            if (!password.value || !passwordVerification.value) {
                setError("Veuillez saisir votre nouveau mot de passe.");
                return;
            }
            if (password.value !== passwordVerification.value) {
                setError("Les mots de passe ne correspondent pas.");
                return;
            }
            const token = params.get("token");
            if (token) {
                await API.organizerPasswordReset(token, password.value);
                setSuccess(true);
            }
            else {
                setError("Le jeton de réinitialisation du mot de passe n'est pas valide.");
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    if (loading) {
        return null;
    }
    return (_jsxs(LoginPageWrapper, { maxWidth: false, children: [_jsx(LoginLogo, { src: logo_black }), _jsx(LoginTitle, { children: "R\u00E9initialisation du mot de passe" }), _jsxs(Box, { width: { xs: "90%", md: "50%" }, margin: "0 auto", children: [!tokenError && success && (_jsxs(Stack, { mt: 2, display: "flex", alignItems: "center", spacing: 2, children: [_jsx(Alert, { severity: "success", children: _jsx("span", { children: "Votre mot de passe a \u00E9t\u00E9 r\u00E9initialis\u00E9 avec succ\u00E8s." }) }), _jsx(Box, { pt: 2, children: _jsx(OrangeButton, { component: Link, to: "/login", children: "Connexion" }) })] })), !tokenError && !success && (_jsxs(Stack, { display: "flex", alignItems: "stretch", spacing: 2, children: [error && (_jsx(Alert, { severity: "error", children: error })), _jsx(PasswordField, { label: "Nouveau mot de passe", ...password }), _jsx(PasswordField, { label: "V\u00E9rification du mot de passe", ...passwordVerification }), _jsx(Box, { pt: 2, children: _jsx(SecondaryButton, { sx: { cursor: "pointer", maxWidth: "none" }, onClick: handlePasswordReset, disabled: !password.value.length || !passwordVerification.value.length, children: "R\u00E9initialiser le mot de passe" }) })] })), !!tokenError && (_jsxs(Stack, { display: "flex", alignItems: "center", spacing: 2, children: [_jsx(Alert, { severity: "error", children: tokenError }), _jsx(Box, { pt: 2, children: _jsx(OrangeButton, { component: Link, to: "/login", children: "Retour" }) })] }))] })] }));
}
