import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { SwapVert, Edit, Visibility, Download } from '@mui/icons-material';
import MediaThumbnail from './MediaThumbnail';
import { routes } from '../../../../../../App';
import { Link, useParams } from 'react-router-dom';
import GenericModal from "../../../../../common/GenericModal";
import { useTranslation } from "react-i18next";
export function ProjectMediaListItem({ media, index, readonly, showDownloadButton, onPreview }) {
    const { projectId } = useParams();
    const theme = useTheme();
    const { t, i18n } = useTranslation(['home', 'main']);
    const [isDownloading, setIsDownloading] = useState(false);
    async function downloadMedia() {
        setIsDownloading(true);
        const url = media.file_path;
        try {
            const originalImage = url;
            const image = await fetch(originalImage, { headers: {
                    "Cache-Control": 'no-cache'
                } });
            // Split image name
            const nameSplit = originalImage.split("/");
            const duplicateName = nameSplit.pop();
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = "" + duplicateName + "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloading(false);
        }
        catch (error) {
            setIsDownloading(false);
            console.log(error);
        }
    }
    function previewMedia() {
        onPreview(media);
    }
    return (_jsx(Draggable, { draggableId: media.id, index: index, isDragDisabled: readonly, children: (provided, snapshot) => (_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ref: provided.innerRef, ...provided.draggableProps, sx: {
                my: 1,
                p: 1.5,
                borderRadius: "8px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                background: snapshot.isDragging ? theme.palette.grey.A100 : "white"
            }, children: [_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(Box, { px: 1, children: _jsx(Typography, { children: index + 1 }) }), _jsx(MediaThumbnail, { type: media.type, url: media.file_path }), _jsx(Box, { flex: 1, px: 1, children: _jsx(Typography, { sx: { wordBreak: "break-word" }, children: media.userName }) })] }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", children: [readonly && (_jsx(IconButton, { size: "small", color: "secondary", onClick: previewMedia, children: _jsx(Visibility, {}) })), (readonly || showDownloadButton) && (_jsx(IconButton, { size: "small", color: "secondary", onClick: downloadMedia, children: _jsx(Download, {}) })), !readonly && (_jsxs(_Fragment, { children: [_jsx(IconButton, { component: Link, to: `/${routes.user}/${routes.messages}/${projectId}/medias/${media.id}`, size: "small", color: "secondary", children: _jsx(Edit, {}) }), _jsx(IconButton, { ...provided.dragHandleProps, size: "small", color: "secondary", children: _jsx(SwapVert, {}) })] })), _jsx(GenericModal, { open: isDownloading, title: t("well_done.dont_leave", { ns: ['main'] }) })] })] })) }, media.id));
}
