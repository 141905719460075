import { createTheme, responsiveFontSizes } from "@mui/material";
export { theme };
const green = "#60967F";
const orange = "#EF7E5D";
const white = "#FFFFFF";
let theme = createTheme({
    typography: {
        fontFamily: "Open Sans",
    },
    palette: {
        primary: {
            main: green,
            contrastText: white,
        },
        secondary: {
            main: orange,
            contrastText: white,
        },
        actionValidation: {
            main: green,
            contrastText: white,
            dark: "#0066ff",
        },
        status: {
            error: "#9d1d32",
            success: "#2d793a",
        },
        backgrounds: {
            login: "#F9F1EC",
            mediaUpload: "#F9F1EC",
        },
    },
    variables: {
        headerHeight: {
            sm: 56,
            default: 64,
        },
    },
});
theme = responsiveFontSizes(theme);
export const getFilter = (color) => {
    if (color === "green") {
        return "invert(56%) sepia(15%) saturate(700%) hue-rotate(102deg) brightness(94%) contrast(94%)";
    }
};
export const getStyledProps = (...props) => ({
    shouldForwardProp: (prop) => !props.includes(prop),
});
