import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { TabSectionTitle } from './ProjectMusicTab.styles';
import { PROJECT_MUSIC_SELECTION_ID, } from './ProjectMusicTab.utils';
import useApiRequest from '../../../../../hooks/api-request';
import UploadFileInput from '../../../../common/UploadFileInput/UploadFileInput';
// @ts-ignore
import musicNote from '../../../../../../public/music_note.svg';
import { useTranslation } from "react-i18next";
const MAX_FILE_SIZE = 100 * 1024 * 1024;
export default function ProjectMusicUpload({ project, onMusicFileAdd, onMusicFileAddFinal, onUploadQueueUpdated }) {
    const API = useApiRequest();
    const [uploadError, setUploadError] = useState(false);
    const { t, i18n } = useTranslation(['home', 'main']);
    async function handleUpload(e) {
        if (e.target?.files) {
            setUploadError(false);
            // validate files size before adding them to the state
            const files = [];
            for (let file of e.target.files) {
                if (file.size < MAX_FILE_SIZE) {
                    files.push(file);
                }
            }
            const selectionContainer = document.getElementById(PROJECT_MUSIC_SELECTION_ID);
            if (selectionContainer) {
                selectionContainer.scrollIntoView({
                    behavior: 'smooth'
                });
            }
            return handleUploadQueue(files);
        }
    }
    async function handleUploadQueue(files) {
        // update loading files of parent component
        onUploadQueueUpdated([...files]);
        // if queue not empty handle upload
        if (files.length > 0) {
            onMusicFileAdd();
            try {
                // get first file in queue and upload it
                const file = files.shift();
                const formData = new FormData();
                formData.append("file", file);
                const { data } = await API.uploadProjectMusicFile(project.id, formData);
                // update parent with uploaded file
                onMusicFileAddFinal(data.musicFile);
            }
            catch (e) {
                setUploadError(true);
            }
            // handle next file in queue
            await handleUploadQueue(files);
        }
    }
    function closeUploadError() {
        setUploadError(false);
    }
    return (_jsxs(Box, { px: 1, py: 2, children: [_jsx(TabSectionTitle, { sx: { color: 'white' }, children: t("dashboard_projects.upload_track", { ns: ['main'] }) }), uploadError && (_jsx(Alert, { onClose: closeUploadError, sx: { mb: 1 }, severity: "error", children: t("commons.upload_error_1", { ns: ['main'] }) })), _jsx(UploadFileInput, { id: "audio-upload", accept: "audio/mpeg, audio/wav, audio/aac", text: t("dashboard_projects.upload_track", { ns: ['main'] }), image: musicNote, onUpload: handleUpload, multiple: true }), _jsxs(Typography, { variant: "body2", sx: { mt: 1, color: 'white' }, children: [t("edit_content.upload_supported", { ns: ['main'] }), " : mp3,  wav, aac."] }), _jsx(Typography, { variant: "body2", sx: { color: 'white' }, children: t("dashboard_projects.upload_track_info_1", { ns: ['main'] }) })] }));
}
