import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
export const LayoutWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    height: "100dvh",
}));
export const LayoutContent = styled((props) => (_jsx(Container, { ...props, maxWidth: false })))(({ theme }) => ({
    height: "-webkit-fill-available",
    padding: `${theme.spacing(2)} !important`,
    WebkitOverflowScrolling: "touch",
}));
