import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Save } from "@mui/icons-material";
import history from 'history/browser';
import { useTranslation } from 'react-i18next';
import { Page, PageTitle } from '../../../common/styles/page.styles';
import useApiRequest from '../../../../hooks/api-request';
import { Box, CircularProgress, Container } from '@mui/material';
import { theme } from '../../../../utils/theme';
import { useProject } from '../../../../contexts/project';
import { routes } from '../../../../App';
import VideoDurationSlider from './VideoDurationSlider';
import CreatomateMediaPreview from '../../../common/CreatomateMediaPreview/CreatomateMediaPreview';
import { ActionsWrapper, ActionsContainer } from './MediaEditDurationPage.styles';
import GenericModal from "../../../common/GenericModal";
let prevMedia;
export default function MediaEditDurationPage() {
    const { t } = useTranslation(['main']);
    const [pathToGo, setPathToGo] = useState("/organisateur/message");
    const [hasChange, setHasChange] = useState(false);
    const [closeWithoutSaveModal, setCloseWithoutSaveModal] = useState(false);
    const { projectId, mediaId } = useParams();
    const navigate = useNavigate();
    const { project, isProjectLoading } = useProject();
    const API = useApiRequest();
    const [media, setMedia] = useState();
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    let unblock = () => { };
    useEffect(() => {
        (async () => {
            if (project && mediaId) {
                try {
                    if (project.files) {
                        const media = project.files.find((media) => media.id === mediaId);
                        if (media.type !== "video") {
                            navigate(`/${routes.user}/${routes.messages}/${project.id}/edit/${media.id}`);
                        }
                        prevMedia = media;
                        setMedia(media);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [project, mediaId]);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Perform actions before the component unloads
            event.preventDefault();
            event.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    useEffect(() => {
        if (!hasChange && unblock != null) {
            unblock();
        }
        unblock = history.block((tx) => {
            setPathToGo(tx.location.pathname);
            return manageBlockingSave(tx.location.pathname);
        });
        return () => {
            unblock();
        };
    }, [hasChange]);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    function manageBlockingSave(pathname) {
        if (hasChange) {
            setCloseWithoutSaveModal(true);
        }
        else {
            navigate(pathname);
        }
    }
    async function saveProjectMedia() {
        if (projectId && mediaId && media) {
            try {
                await API.updateProjectFile(projectId, mediaId, media);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    function updateMediaDuration(startAt, endAt) {
        if (media) {
            setHasChange(true);
            setMedia({ ...media, startAt, endAt });
        }
    }
    if (isProjectLoading || !project || !media) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { maxWidth: false, children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(PageTitle, { children: media.userName }), _jsx(CreatomateMediaPreview, { media: media, orientation: project.orientation })] }), _jsx(ActionsWrapper, { children: _jsxs(ActionsContainer, { children: [_jsx(VideoDurationSlider, { duration: media.duration, startAt: media.startAt, endAt: media.endAt, onChange: updateMediaDuration }), _jsx(Container, { sx: {
                                width: 50,
                                height: 50,
                                borderRadius: "8px",
                                background: hasChange ? theme.palette.secondary.main : 'lightgray',
                                color: theme.palette.common.white,
                                border: "none",
                                outline: "none",
                                cursor: hasChange ? "pointer" : "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "background 0.2s ease-in-out",
                                ["&:hover"]: {
                                    background: hasChange ? theme.palette.primary.main : 'lightgray',
                                }
                            }, onClick: async () => {
                                if (hasChange) {
                                    setHasChange(false);
                                    if (media && (media?.startAt !== prevMedia?.startAt || media?.endAt !== prevMedia?.endAt)) {
                                        await saveProjectMedia();
                                    }
                                }
                            }, children: _jsx(Save, { sx: { fontSize: 20, color: "white" } }) })] }) }), _jsx(GenericModal, { title: t("commons.unsaved_changes", { ns: ['main'] }), open: closeWithoutSaveModal, onConfirm: async () => {
                    if (hasChange) {
                        setHasChange(false);
                        if (media && (media?.startAt !== prevMedia?.startAt || media?.endAt !== prevMedia?.endAt)) {
                            await saveProjectMedia();
                        }
                    }
                    setCloseWithoutSaveModal(false);
                    navigate(pathToGo);
                }, onCancel: () => {
                    setCloseWithoutSaveModal(false);
                    navigate(pathToGo);
                } })] }));
}
