import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
export default function ProjectMusicLoadingFiles({ files }) {
    return (_jsx(Box, { display: "flex", flexDirection: "column", children: files.map((file, index) => (_jsx(Box, { children: _jsx(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", sx: {
                    my: 1,
                    p: 1.5,
                    borderRadius: "8px",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                    background: "white"
                }, children: _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", children: [_jsx(CircularProgress, { sx: { mr: 0.5, ml: 0.5 }, color: "secondary" }), _jsx(Typography, { children: file.name })] }) }) }, file.name + 1))) }));
}
