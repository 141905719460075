import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import LayoutHeader from "./LayoutHeader";
import { LayoutContent, LayoutWrapper } from './styles/layouts.styles';
export function GlobalLayout() {
    return (_jsxs(LayoutWrapper, { children: [_jsx(LayoutHeader, {}), _jsx(Box, { component: "main", sx: {
                    flex: 1,
                    overflow: "auto",
                }, children: _jsx(LayoutContent, { children: _jsx(Outlet, {}) }) })] }));
}
