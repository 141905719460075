import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, PageTitle } from '../../common/styles/page.styles';
import { ProjectStatus } from '../../../models/project';
import useApiRequest from '../../../hooks/api-request';
import { routes } from '../../../App';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { theme } from '../../../utils/theme';
import ProjectPreviewVideo from '../ProjectPreviewVideo';
import FinalVideoLoadingModal from './FinalVideoLoadingModal';
import { getLastOrder, isOrderPaid } from '../../../utils/orders';
import { ProjectVideo } from './ProjectVideo';
import DownloadButton from './DownloadButton';
import ShareButton from './ShareButton';
import { useTranslation } from "react-i18next";
import useUser from '../../../hooks/user';
const pause = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const ONE_MINUTE_MS = 60000;
export default function FinalVideoPage() {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const { user, isSubscriptionBasedOrganizer } = useUser();
    const API = useApiRequest();
    const [project, setProject] = useState();
    const { t, i18n } = useTranslation(['home', 'main']);
    useEffect(() => {
        (async () => {
            if (projectId) {
                try {
                    const projectResponse = await API.getProject(projectId);
                    const project = projectResponse.data.project;
                    // Verify last order status and redirect to preview
                    // If organizer has not a subscription based payment type
                    if (project.status === ProjectStatus.ACTIVE && !isSubscriptionBasedOrganizer()) {
                        const ordersResponse = await API.getProjectOrders(project.id);
                        const order = getLastOrder(ordersResponse.data.orders);
                        if (!order || !isOrderPaid(order)) {
                            if (user?.email === project.organizer) {
                                navigate(`/${routes.user}/${routes.messages}/${projectId}/preview`);
                            }
                            else {
                                navigate(`/${routes.contributor}/${routes.messages}/${projectId}`);
                            }
                            return;
                        }
                    }
                    setProject(project);
                }
                catch (error) {
                    console.log('Error white loading project', error);
                }
            }
        })();
    }, [projectId]);
    useEffect(() => {
        // Variable to ensure effect stop running after component cleanup
        let isEffectActive = true;
        (async () => {
            if (project && !project.finalVideo) {
                // Wait 60 sec before first verification
                await pause(ONE_MINUTE_MS);
                try {
                    let completed = false;
                    let attempts = 1;
                    while (!completed && attempts <= 10 && isEffectActive) {
                        const response = await API.getProject(project.id);
                        completed = !!response.data.project.finalVideo;
                        if (completed) {
                            setProject(response.data.project);
                        }
                        else {
                            await pause(ONE_MINUTE_MS);
                        }
                        attempts++;
                    }
                }
                catch (error) {
                    console.log('Error verifying project status', error);
                }
            }
        })();
        return () => {
            isEffectActive = false;
        };
    }, [project]);
    function handleModalClose() {
        navigate(`/${routes.user}/${routes.messages}/${projectId}`);
    }
    if (!project) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsxs(Page, { sx: { padding: "0 !important" }, children: [_jsx(PageTitle, { children: t("well_done.well_done_title", { ns: ['main'] }) }), _jsx(Typography, { sx: { mt: 1, textAlign: "center" }, children: t("well_done.well_done_descr", { ns: ['main'] }) }), _jsxs(Container, { sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }, children: [_jsx(Box, { sx: { my: 2, width: '100%', textAlign: "center" }, children: project?.finalVideo
                            ? _jsx(ProjectVideo, { project: project })
                            : _jsx(ProjectPreviewVideo, { project: project }) }), _jsxs(Box, { sx: { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "middle" }, children: [_jsx(DownloadButton, { project: project }), _jsx(ShareButton, { project: project })] })] }), _jsx(FinalVideoLoadingModal, { open: project.status !== ProjectStatus.COMPLETED && !isSubscriptionBasedOrganizer(), onClose: handleModalClose })] }));
}
