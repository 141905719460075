import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
export const InfoTooltipContainer = styled(({ className, children, ...props }) => (_jsx(Tooltip, { ...props, children: children, classes: { popper: className } })))(({ theme }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
        backgroundColor: theme.palette.secondary.main,
    },
    [`& .${tooltipClasses.arrow}`]: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.secondary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        maxWidth: 220,
    },
    [`& .${tooltipClasses.tooltip} p`]: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.secondary.contrastText,
    },
}));
export const InfoTooltipImageContainer = styled('span')(({ theme }) => ({
    margin: theme.spacing(0, 0.5),
    display: "inline-block",
    padding: theme.typography.pxToRem(2),
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    borderRadius: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    border: `1px solid ${theme.palette.secondary.main}`,
    textAlign: "center",
}));
export const InfoTooltipImage = styled('img')(({ theme }) => ({
    width: theme.typography.pxToRem(7),
}));
export const ShareProjectTooltipContainer = styled(({ className, children, ...props }) => (_jsx(Tooltip, { ...props, children: children, classes: { popper: className } })))(({ theme }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
        backgroundColor: theme.palette.common.black,
    },
    [`& .${tooltipClasses.arrow}`]: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        border: `1px solid ${theme.palette.common.black}`,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.contrastText,
        maxWidth: 100,
        padding: 10,
        borderRadius: 10,
    },
}));
