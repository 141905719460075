import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Video, ImageContainer, ImageWithinContainer } from './MediaPreview.styles';
export const MediaID = "media-edit-preview";
export const MediaContainerID = "media-edit-preview-container";
export function MediaPreview({ media, orientation, onLoad, controls = false }) {
    const [videoTrimQuery, setVideoTrimQuery] = React.useState("");
    useEffect(() => {
        if (media.type === "video") {
            let q = "";
            if (typeof media.startAt === "number" && typeof media.endAt === "number") {
                q += `#t=${media.startAt},${media.endAt}`;
            }
            setVideoTrimQuery(q);
        }
    }, [media]);
    useEffect(() => {
        if (media.type === "video") {
            const video = document.getElementById(MediaID);
            if (video && media.startAt) {
                video.currentTime = media.startAt;
            }
        }
    }, [media]);
    function handleLoad() {
        if (typeof onLoad === 'function') {
            onLoad();
        }
    }
    // Note: for images we need to place them inside a container to preserve
    // the aspect ratio as it will be in the final video render
    if (media.type === "image") {
        return (_jsx(ImageContainer, { id: MediaID, orientation: orientation, children: _jsx(ImageWithinContainer, { src: media.file_path, alt: `Image de ${media.userName}`, onLoad: handleLoad }) }));
    }
    if (media.type === "video") {
        return (_jsx(Video, { id: MediaID, controls: controls, orientation: orientation, onLoadedData: handleLoad, children: _jsx("source", { src: `${media.preview_file_path ? media.preview_file_path : media.file_path}${videoTrimQuery}`, type: "video/mp4" }) }));
    }
    return null;
}
