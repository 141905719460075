import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import useUser from '../../hooks/user';
import { routes } from '../../App';
import { OrangeButton } from '../common/styles/buttons.styles';
import { PageWrapper, Message } from './NotAuthorized.styles';
export default function NotAuthorizedPage() {
    const { user, role, removeUserSession, isOrganizer } = useUser();
    const returnLink = user && isOrganizer()
        ? `/${routes.user}/${routes.messages}`
        : '/login';
    /**
     * Effect that ensure user session is removed
     * if user is not an organizer
     */
    useEffect(() => {
        if (user && !isOrganizer()) {
            removeUserSession();
        }
    }, [user, role]);
    return (_jsxs(PageWrapper, { maxWidth: false, children: [_jsx(Message, { children: "Vous n'\u00EAtes pas autoris\u00E9 \u00E0 acc\u00E9der \u00E0 cette page" }), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(OrangeButton, { component: Link, to: returnLink, children: "Retour" }) })] }));
}
