import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Stack } from '@mui/material';
import { QrCode2, Link as LinkIcon, Share } from '@mui/icons-material';
import { WhatsappIcon, WhatsappShareButton, 
// FacebookMessengerShareButton,
// FacebookMessengerIcon,
TelegramShareButton, TelegramIcon, } from 'react-share';
import { BlockBox, BlockBoxTitle, BlockBoxText } from '../../common/styles/boxes.styles';
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../../App';
import { isMobile } from '../../../utils/devices';
import { useTranslation } from "react-i18next";
export default function InviteLinks({ onLinkCopied, project }) {
    const { projectId } = useParams();
    const invitationUrl = `${process.env.REACT_APP_BASE_URL}/${routes.contributor}/${routes.messages}/${projectId}/login/${project?.isProfessional ? "1" : "0"}`;
    const shareTitle = `Vous êtes invité à participer à une vidéo collective !`;
    const { t, i18n } = useTranslation(['home', 'main']);
    function copyInvitationLink() {
        navigator.clipboard.writeText(invitationUrl);
        // display success alert for 5 seconds
        onLinkCopied(true);
        setTimeout(() => {
            onLinkCopied(false);
        }, 5000);
    }
    async function handleShare() {
        if (!!navigator.share) {
            try {
                await navigator.share({
                    title: shareTitle,
                    url: invitationUrl,
                    text: shareTitle,
                });
                console.log("Vidéo partagée avec succès.");
            }
            catch (error) {
                console.error(`Le partage de la vidéo a échoué - ${error}.`);
            }
        }
    }
    return (_jsxs(BlockBox, { children: [_jsx(BlockBoxTitle, { children: t("invitation.invite_link", { ns: ['main'] }) }), _jsx(BlockBoxText, { children: t("invitation.invite_descr", { ns: ['main'] }) }), _jsxs(Stack, { mt: 2, direction: "row", spacing: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Box, { component: Link, to: `./qr-code/${project?.isProfessional ? "1" : "0"}`, children: _jsx(QrCode2, { fontSize: "large", color: "primary" }) }), _jsx(LinkIcon, { fontSize: "large", color: "primary", sx: { cursor: 'pointer' }, onClick: copyInvitationLink }), !!navigator.share && isMobile() && (_jsx(Box, { onClick: handleShare, children: _jsx(Share, { fontSize: "large", color: "primary" }) })), _jsx(WhatsappShareButton, { title: shareTitle, url: invitationUrl, children: _jsx(WhatsappIcon, { size: 32, round: true }) }), _jsx(TelegramShareButton, { title: shareTitle, url: invitationUrl, children: _jsx(TelegramIcon, { size: 32, round: true }) })] })] }));
}
