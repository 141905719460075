import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Box, Chip } from '@mui/material';
import { ProjectStatus } from '../../../models/project';
import useUser from '../../../hooks/user';
import { useProject } from '../../../contexts/project';
import { routes } from "../../../App";
import { Page, PageTitle, SettingsIcon } from '../../common/styles/page.styles';
import { ProjectContent } from './ProjectContent/ProjectContent';
export default function ProjectDashboardPage() {
    const navigate = useNavigate();
    const { t } = useTranslation(['home', 'main']);
    const { project, isProjectLoading, updateProject } = useProject();
    const { projectId } = useParams();
    const { isOrganizer } = useUser();
    if (isProjectLoading || !project) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Page, { children: [_jsxs(Box, { py: 2, justifyContent: "center", display: "flex", children: [_jsx(PageTitle, { children: t("dashboard_projects.edit_project", { ns: ['main'] }) }), "\u2002", isOrganizer() && !readonly && (_jsx(SettingsIcon, { onClick: () => {
                            navigate(`/${routes.user}/${routes.messages}/${projectId}/edit`);
                        } }))] }), _jsxs(Box, { py: 2, textAlign: "center", children: [_jsx(Typography, { fontSize: 18, pb: 1, children: project.name }), project.status === ProjectStatus.COMPLETED && (_jsx(Chip, { label: t("my_projects.finalized_project", { ns: ['main'] }), color: "primary", size: "small" }))] }), isOrganizer() && (_jsx(Box, { py: 2, children: _jsx(ProjectContent, { project: project, updateProject: updateProject }) }))] }));
}
