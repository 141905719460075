import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Box, Stack } from '@mui/material';
import { SecondaryWhiteButton } from '../../../../../common/styles/buttons.styles';
import { ModalWrapper, ModalContent, ModalTitle, ModalCloseButton } from '../../../../../common/styles/modals.styles';
import CreatomateMediaPreview from '../../../../../common/CreatomateMediaPreview/CreatomateMediaPreview';
export default function ProjectMediaPreviewModal({ media, orientation, readonly, onClose, onDelete }) {
    if (!media) {
        return null;
    }
    return (_jsx(Modal, { open: !!media, onClose: onClose, children: _jsx(ModalWrapper, { children: _jsxs(ModalContent, { children: [_jsx(ModalCloseButton, { onClick: onClose }), _jsx(ModalTitle, { children: media.userName }), _jsx(Box, { mt: 2, children: _jsx(CreatomateMediaPreview, { media: media, orientation: orientation }) }), !readonly && (_jsx(Stack, { mt: 4, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: _jsx(SecondaryWhiteButton, { onClick: onDelete, sx: { maxWidth: "none" }, children: "Supprimer le contenu" }) }))] }) }) }));
}
