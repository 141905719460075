import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Typography, Box, Popover } from '@mui/material';
import { useParams, Link } from "react-router-dom";
import { WhiteSecondaryButton } from '../../common/styles/buttons.styles';
import { routes } from "../../../App";
import { Page } from '../../common/styles/page.styles';
import { CheckImage, Logo, ThanksMessage } from './ContributorThanksPage.styles';
import UploadErrorModal from '../MediaUpload/UploadErrorModal';
// @ts-ignore
import logo_black from "../../../../public/logo_black.svg";
// @ts-ignore
import checkOrangeIcon from "../../../../public/check_orange.svg";
import useUrlSearchParams from '../../../hooks/url-search-params';
import { useTranslation } from "react-i18next";
import { STORAGE_KEY_GENERAL_KEY, STORAGE_KEY_MEMORABLE_LANGUAGE } from "../../../utils/storageKeys";
export default function ContributorThanksPage() {
    const { projectId } = useParams();
    const params = useUrlSearchParams();
    const [hasInvalidFiles, setHasInvalidFiles] = React.useState(!!params.get("has_invalid_files"));
    const [isUploadErrorModalOpen, setUploadErrorModalOpen] = useState(false);
    const [isPopoverLangOpen, setisPopoverLangOpen] = useState(false);
    const { t, i18n } = useTranslation(['home', 'main']);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const idPopover = 'simple-popover';
    const handleClosePopover = () => {
        setAnchorEl1(null);
        setisPopoverLangOpen(false);
    };
    const onClickLanguageChange = (lang) => {
        i18n.changeLanguage(lang); //change the language
        if (lang != undefined && lang != null && (lang == 'en' || lang == 'fr')) {
            localStorage.setItem(STORAGE_KEY_GENERAL_KEY + STORAGE_KEY_MEMORABLE_LANGUAGE, lang);
        }
    };
    useEffect(() => {
        if (hasInvalidFiles) {
            setUploadErrorModalOpen(true);
        }
    }, [hasInvalidFiles]);
    function handleUploadErrorModalClose() {
        setUploadErrorModalOpen(false);
    }
    return (_jsxs(Page, { sx: { display: "flex", flexDirection: "column", height: "100%" }, children: [_jsxs("div", { style: {
                    position: 'absolute',
                    right: 0,
                    marginRight: "1%",
                    top: 28,
                }, children: [_jsx("img", { onClick: () => {
                            setisPopoverLangOpen(true);
                        }, style: { width: 40 }, alt: "United States", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }, children: [_jsx(Typography, { onClick: () => {
                                    onClickLanguageChange('en');
                                    setisPopoverLangOpen(false);
                                }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                    onClickLanguageChange('fr');
                                    setisPopoverLangOpen(false);
                                }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), _jsxs(Box, { flex: 3, pt: 4, pb: 4, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", children: [_jsx(Logo, { src: logo_black }), _jsx(ThanksMessage, { py: 2, children: t("onboarding_participants.thank_you", { ns: ['main'] }) }), _jsx(CheckImage, { alt: "check", src: checkOrangeIcon }), _jsx(Typography, { py: 2, textAlign: "center", children: t("onboarding_participants.content_well_transmitted", { ns: ['main'] }) }), hasInvalidFiles && (_jsx(UploadErrorModal, { open: isUploadErrorModalOpen, error: "Certains fichiers ne sont pas valides et n'ont pas pu \u00EAtre ajout\u00E9s.", onClose: handleUploadErrorModalClose }))] }), _jsx(Box, { flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", children: _jsx(WhiteSecondaryButton, { component: Link, to: `/${routes.contributor}/${routes.messages}/${projectId}/media/ajouter`, children: t("commons.add_new_content", { ns: ['main'] }) }) })] }));
}
