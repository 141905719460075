import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid } from '@mui/material';
import { MUSIC_LIBRARY } from '../ProjectMusicTab.utils';
import { TabSectionTitle } from '../ProjectMusicTab.styles';
import ProjectMusicLibraryListItem from './ProjectMusicLibraryListItem';
import { useTranslation } from "react-i18next";
export default function ProjectMusicLibrary({ playingMusicFileId, onMusicPlay, onMusicPause, onMusicAdd, color: any }) {
    const { t, i18n } = useTranslation(['home', 'main']);
    return (_jsxs(Box, { px: 1, py: 2, children: [_jsx(TabSectionTitle, { sx: { color: 'white' }, children: t("dashboard_projects.choose_track", { ns: ['main'] }) }), _jsx(Grid, { container: true, spacing: 3, children: MUSIC_LIBRARY.map((item) => (_jsx(ProjectMusicLibraryListItem, { item: item, isPlaying: item.id === playingMusicFileId, onPlay: onMusicPlay, onPause: onMusicPause, onAdd: onMusicAdd }, item.id))) })] }));
}
