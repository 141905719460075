import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Box, Alert } from '@mui/material';
import { Archive, VideoCameraBack, FileDownload } from "@mui/icons-material";
import { ProjectStatus } from '../../../models/project';
import { useProject } from '../../../contexts/project';
import useApiRequest from '../../../hooks/api-request';
import { Page, PageTitle } from '../../common/styles/page.styles';
import GenericModal from '../../common/GenericModal';
import ExportActionButton from './ExportActionButton';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const MAX_SLEEP_DELAY = 60000;
export default function ProjectExportPage() {
    const { t } = useTranslation(['home', 'main']);
    const { project, isProjectLoading, updateProject } = useProject();
    const API = useApiRequest();
    const [isDownloadingProjectContentsZip, setIsDownloadingProjectContentsZip] = useState(false);
    const [isDownloadingProjectRender, setIsDownloadingProjectRender] = useState(false);
    const [projectFilesSignature, setProjectFilesSignature] = useState();
    useEffect(() => {
        (async () => {
            if (project) {
                try {
                    const response = await API.getProjectFilesSignature(project.id);
                    setProjectFilesSignature(response.data.signature);
                }
                catch (e) {
                    console.error("Error while fetching project files signature", e);
                }
            }
        })();
    }, [project]);
    useEffect(() => {
        (async () => {
            if (project?.status === ProjectStatus.FINALIZING) {
                try {
                    await verifyProjectRenderStatusRec(project.id);
                }
                catch (e) {
                    console.error("Error while checking project render status", e);
                }
            }
        })();
    }, [project]);
    async function handleContentZipDownload() {
        if (project) {
            try {
                setIsDownloadingProjectContentsZip(true);
                const response = await API.getProjectContentChunksZip(project.id);
                const responseChunksZipPath = response.data.projectFiles;
                const link = document.createElement("a");
                link.href = responseChunksZipPath;
                link.download = `${project.id}.zip`;
                link.click();
            }
            catch (e) {
                console.error("Error while downloading project contents zip", e);
            }
            finally {
                setIsDownloadingProjectContentsZip(false);
            }
        }
    }
    async function handleProjectFinalVideoDownload() {
        if (project) {
            try {
                setIsDownloadingProjectRender(true);
                const response = await axios({
                    url: project?.finalVideo,
                    method: "GET",
                    responseType: "blob",
                });
                const urlObject = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = urlObject;
                link.download = `${project.id}.mp4`;
                link.click();
            }
            catch (e) {
                console.error("Error while downloading project render", e);
            }
            finally {
                setIsDownloadingProjectRender(false);
            }
        }
    }
    async function handleProjectFinalVideoRender() {
        if (project) {
            try {
                const renderResponse = await API.createNewProjectRender(project.id);
                console.log('create render response', renderResponse.data);
                const projectResponse = await API.getProject(project.id);
                updateProject(projectResponse.data.project);
            }
            catch (e) {
                console.error("Error while starting project render", e);
            }
        }
    }
    async function verifyProjectRenderStatusRec(projectId, delay = 10000) {
        await sleep(delay);
        try {
            const response = await API.getProject(projectId);
            const tempProject = response.data.project;
            if (tempProject.status === ProjectStatus.FINALIZING) {
                return verifyProjectRenderStatusRec(projectId, Math.min(delay * 2, MAX_SLEEP_DELAY));
            }
            else {
                updateProject(tempProject);
            }
        }
        catch (e) {
            console.error("Error while verifying project render status", e);
        }
    }
    if (isProjectLoading || !project) {
        return null;
    }
    /**
     * Determine whether the project final video is up-to-date with current project state
     * And can be downloaded, or need to be re-rendered to include latest changes
     */
    const isProjectFinalVideoUpToDate = (project.status === ProjectStatus.ACTIVE
        && project?.finalVideo
        && projectFilesSignature === project?.finalVideoId);
    /**
     * Determine whether the project is empty and has no files to download or process
     */
    const isProjectEmpty = !project?.files || project?.files.length === 0;
    return (_jsxs(Page, { children: [_jsx(Box, { py: 2, children: _jsx(PageTitle, { children: project.name }) }), _jsxs(Box, { py: 4, children: [isProjectEmpty && (_jsx(Box, { pb: 2, children: _jsx(Alert, { severity: "warning", children: t("project_export_page.empty_project_message", { ns: ['main'] }) }) })), _jsx(Box, { py: 1, textAlign: "center", children: _jsx(ExportActionButton, { label: t("project_export_page.download_content_zip_button_label", { ns: ['main'] }), icon: _jsx(Archive, { color: "primary" }), loading: isDownloadingProjectContentsZip, disabled: isProjectEmpty, onClick: handleContentZipDownload }) }), _jsxs(Box, { py: 1, textAlign: "center", children: [isProjectFinalVideoUpToDate && (_jsx(ExportActionButton, { label: t("project_export_page.download_project_render_button_label", { ns: ['main'] }), icon: _jsx(FileDownload, { color: "primary" }), loading: isDownloadingProjectRender, disabled: isProjectEmpty, onClick: handleProjectFinalVideoDownload })), !isProjectFinalVideoUpToDate && (_jsx(ExportActionButton, { label: t(`project_export_page.${project.status === ProjectStatus.ACTIVE ? "create_new_project_render_button_label" : "create_new_project_render_button_loading_label"}`, { ns: ['main'] }), icon: _jsx(VideoCameraBack, { color: "primary" }), loading: project.status !== ProjectStatus.ACTIVE, disabled: isProjectEmpty, onClick: handleProjectFinalVideoRender }))] })] }), _jsx(GenericModal, { open: isDownloadingProjectContentsZip || isDownloadingProjectRender, title: t("well_done.dont_leave", { ns: ['main'] }) })] }));
}
