import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ProjectStatus } from '../../../models/project';
import useUser from '../../../hooks/user';
import { routes } from '../../../App';
import { IconImage, MenuContainer, MenuItemContainer, MenuItemDivider, MenuItemIconButton, MenuItemText } from '../styles/bottom-navigation-menu.styles';
import { useProject } from '../../../contexts/project';
// @ts-ignore
import edit from "../../../../public/edit-project.svg";
// @ts-ignore
import plane from "../../../../public/plane.svg";
// @ts-ignore
import play from "../../../../public/play.svg";
// @ts-ignore
import cartGreenIcon from "../../../../public/cart_green.svg";
// @ts-ignore
import exportIcon from "../../../../public/export.svg";
export default function ProjectEditNavigation() {
    const location = useLocation();
    const { t } = useTranslation(['home', 'main']);
    const { isContributor, isSubscriptionBasedOrganizer } = useUser();
    const { project, isProjectLoading } = useProject();
    if (isContributor() || isProjectLoading || !project) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(MenuContainer, { children: [!readonly && (_jsx(_Fragment, { children: _jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}`, className: location.pathname.endsWith(project.id) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { src: edit, alt: "editer le projet" }) }), _jsx(MenuItemText, { children: t("commons.edit", { ns: ['main'] }) })] }) })), !readonly && (_jsxs(_Fragment, { children: [_jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}/invite`, className: location.pathname.endsWith(`${project.id}/invite`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { src: plane, alt: "inviter des participants" }) }), _jsx(MenuItemText, { children: t("commons.share_invit", { ns: ['main'] }) })] })] })), _jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { component: Link, to: readonly
                    ? `/${routes.user}/${routes.messages}/${project.id}/merci`
                    : `/${routes.user}/${routes.messages}/${project.id}/preview`, className: location.pathname.endsWith(readonly ? `${project.id}/merci` : `${project.id}/preview`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { src: play, alt: "visionner le projet", style: {
                                position: "relative",
                                left: "2px",
                            } }) }), _jsx(MenuItemText, { children: t("commons.preview", { ns: ['main'] }) })] }), !readonly && !isSubscriptionBasedOrganizer() && (_jsxs(_Fragment, { children: [_jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}/panier`, className: location.pathname.endsWith(`${project.id}/panier`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { alt: "commande", src: cartGreenIcon }) }), _jsx(MenuItemText, { children: t("commons.checkout", { ns: ['main'] }) })] })] })), isSubscriptionBasedOrganizer() && (_jsxs(_Fragment, { children: [_jsx(MenuItemDivider, {}), _jsxs(MenuItemContainer, { component: Link, to: `/${routes.user}/${routes.messages}/${project.id}/exporter`, className: location.pathname.endsWith(`${project.id}/exporter`) ? "active" : "", children: [_jsx(MenuItemIconButton, { children: _jsx(IconImage, { alt: "export", src: exportIcon }) }), _jsx(MenuItemText, { children: t("commons.export", { ns: ['main'] }) })] })] }))] }));
}
