import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
export const TabSectionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontStyle: "italic",
    fontSize: "1.1rem",
    color: theme.palette.primary.main,
    textAlign: "left",
    fontFamily: "var(--main_font_family_secondary)",
}));
export const EmptyTypography = styled(Typography)(({ theme }) => ({
    fontSize: "0.9rem",
    fontStyle: "italic",
    color: theme.palette.text.disabled,
}));
