export var UserRole;
(function (UserRole) {
    UserRole["Organizer"] = "organizer";
    UserRole["Contributor"] = "contributor";
})(UserRole || (UserRole = {}));
export var OrganizerAccountType;
(function (OrganizerAccountType) {
    OrganizerAccountType[OrganizerAccountType["Individual"] = 0] = "Individual";
    OrganizerAccountType[OrganizerAccountType["Enterprise"] = 1] = "Enterprise";
})(OrganizerAccountType || (OrganizerAccountType = {}));
export var OrganizerPaymentType;
(function (OrganizerPaymentType) {
    OrganizerPaymentType[OrganizerPaymentType["Project"] = 0] = "Project";
    OrganizerPaymentType[OrganizerPaymentType["Subscription"] = 1] = "Subscription";
})(OrganizerPaymentType || (OrganizerPaymentType = {}));
