import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Alert, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { isDate, isFuture } from 'date-fns';
import { fr } from "date-fns/locale";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { OrangeButton } from "../common/styles/buttons.styles";
import { Page, PageTitle } from '../common/styles/page.styles';
import useApiRequest from '../../hooks/api-request';
import { getRequestErrorMessage } from '../../utils/errors';
import { useTranslation } from "react-i18next";
function EditMessage() {
    const navigate = useNavigate();
    const API = useApiRequest();
    const [project, setProject] = useState();
    const [error, setError] = useState(null);
    const { projectId } = useParams();
    const { t, i18n } = useTranslation(['home', 'main']);
    const { control, handleSubmit, reset, formState } = useForm({
        defaultValues: {
            name: "",
            orientation: "portrait",
            endDate: null,
            description: "",
        },
    });
    useEffect(() => {
        (async () => {
            if (projectId) {
                try {
                    const response = await API.getProject(projectId);
                    setProject(response.data.project);
                }
                catch (error) {
                    const message = getRequestErrorMessage(error);
                    setError(message);
                }
            }
        })();
    }, [projectId]);
    useEffect(() => {
        if (project) {
            reset({
                name: project.name,
                endDate: new Date(project?.endDate),
                orientation: project.orientation,
                description: project.description,
            });
        }
    }, [project, reset]);
    async function onSubmit(values) {
        try {
            if (project) {
                const response = await API.updateProject(project.id, values);
                setProject(response.data.project);
                navigate(-1);
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: t("dashboard_projects.edit_project_informations", { ns: ['main'] }) }), _jsxs("form", { className: "flex flex-col", onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("edit_content.video_title", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "name", control: control, rules: {
                                    validate: (value) => value.trim().length > 0,
                                }, render: ({ field }) => (_jsx(TextField, { InputLabelProps: { shrink: true }, sx: { width: "100%" }, id: "name", label: "", variant: "standard", type: "text", required: true, ...field })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.content_deadline", { ns: ['main'] }), " ", _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "endDate", control: control, rules: {
                                    required: true,
                                    validate: (value) => {
                                        return !!value && isDate(new Date(value)) && isFuture(new Date(value));
                                    },
                                }, render: ({ field }) => (_jsx(LocalizationProvider, { adapterLocale: fr, dateAdapter: AdapterDateFns, children: _jsx(DatePicker, { disablePast: true, format: "dd/MM/yyyy", sx: { width: "fit-content", display: "flex", mt: 1 }, label: "dd/mm/yyyy", slotProps: {
                                            textField: {
                                                ...(formState?.errors?.endDate && {
                                                    error: true,
                                                    helperText: t("create_projects.please_select_date", { ns: ['main'] }),
                                                }),
                                                required: true,
                                            },
                                        }, ...field }) })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", sx: { mb: 1 }, children: [t("create_projects.message_to_participants", { ns: ['main'] }), " "] }), _jsx(Controller, { name: "description", control: control, render: ({ field }) => (_jsx(TextField, { InputLabelProps: { shrink: true }, sx: { width: "100%" }, multiline: true, id: "description", variant: "outlined", type: "text", ...field })) })] }), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsx(OrangeButton, { type: "submit", variant: "contained", disabled: !formState.isValid || formState.isSubmitting, children: t("commons.save_modifications_2", { ns: ['main'] }) }) })] }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) }))] }));
}
export default EditMessage;
