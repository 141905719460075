import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import LayoutHeader from './LayoutHeader';
import { LayoutContent, LayoutWrapper } from './styles/layouts.styles';
import SocialLinksFooter from './SocialLinksFooter';
/**
 * A layout for the final video pages, with a social links footer
 */
export function FinalVideoLayout() {
    return (_jsxs(LayoutWrapper, { children: [_jsx(LayoutHeader, {}), _jsxs(Box, { component: "main", sx: {
                    flex: 1,
                    overflow: "auto",
                }, children: [_jsx(LayoutContent, { sx: { minHeight: "calc(100% - 40px)", height: "auto", overflow: "auto" }, children: _jsx(Outlet, {}) }), _jsx(SocialLinksFooter, {})] })] }));
}
