import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Stack, useTheme } from '@mui/material';
import { ModalCloseButton, ModalTitle, ModalWrapper, SmallModalContent } from '../../common/styles/modals.styles';
import { SecondaryWhiteButton, } from '../../common/styles/buttons.styles';
export default function DeleteProjectModal({ open, onClose, onDelete }) {
    const theme = useTheme();
    return (_jsx(Modal, { open: open, onClose: onClose, "aria-labelledby": "supprimer un projet", "aria-describedby": "confirmation de la suppression d'un projet", children: _jsx(ModalWrapper, { children: _jsxs(SmallModalContent, { display: "flex", flexDirection: "column", justifyContent: "center", children: [_jsx(ModalCloseButton, { onClick: onClose }), _jsx(ModalTitle, { sx: { pt: 0 }, children: "\u00CAtes-vous s\u00FBr de vouloir supprimer cette vid\u00E9o collective ?" }), _jsxs(Stack, { mt: 4, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: [_jsx(SecondaryWhiteButton, { onClick: onDelete, sx: {
                                    maxWidth: "none",
                                    background: theme.palette.common.white,
                                    color: theme.palette.secondary.main
                                }, children: "Oui" }), _jsx(SecondaryWhiteButton, { onClick: onClose, sx: {
                                    maxWidth: "none",
                                }, children: "Non" })] })] }) }) }));
}
