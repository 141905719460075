import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import validator from 'validator';
import { Box, TextField, Typography } from '@mui/material';
import { GreenButton } from '../../common/styles/buttons.styles';
import { BlockBox, BlockBoxText, BlockBoxTitle } from '../../common/styles/boxes.styles';
import InfoTooltip from '../../common/tooltips/InfoTooltip';
import { getRequestErrorMessage } from '../../../utils/errors';
import useApiRequest from '../../../hooks/api-request';
import { useTranslation } from "react-i18next";
export default function InviteEmailForm({ onEmailsAdd, onEmailsError }) {
    const { projectId } = useParams();
    const API = useApiRequest();
    const { t, i18n } = useTranslation(['home', 'main']);
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            email: "",
        },
    });
    async function onSubmit(values) {
        try {
            // Prepare emails
            const emails = values.email.split(",")
                .map((email) => email.trim())
                .filter((email) => validator.isEmail(email));
            if (projectId && emails.length > 0) {
                // Add contributors to project
                const response = await API.createProjectInviteEmails(projectId, emails);
                // Update state and send invites
                onEmailsAdd(response.data.emails);
            }
            // Reset form
            reset();
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            onEmailsError(message);
        }
    }
    return (_jsxs(BlockBox, { children: [_jsx(BlockBoxTitle, { children: t("invitation.invite_mail", { ns: ['main'] }) }), _jsxs(BlockBoxText, { children: [t("invitation.invite_mail_descr_1", { ns: ['main'] }), _jsx(InfoTooltip, { children: _jsx(Typography, { children: t("invitation.invite_descr_tooltip_1", { ns: ['main'] }) }) }), _jsx("br", {}), t("invitation.invite_mail_descr_2", { ns: ['main'] })] }), _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(Box, { sx: { mt: 1, display: 'flex', alignItems: 'flex-end' }, children: [_jsx(Box, { sx: { flex: 1 }, children: _jsx(Controller, { name: "email", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "email", label: t("invitation.add_emails", { ns: ['main'] }), variant: "standard", type: "text", ...field })) }) }), _jsx(Box, { pl: 2, children: _jsx(GreenButton, { type: "submit", children: t("commons.send", { ns: ['main'] }) }) })] }) })] }));
}
