import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';
import { Box, IconButton, Checkbox, Modal, Typography, FormControlLabel, useTheme } from '@mui/material';
import { ProjectStatus } from '../../../../../models/project';
import { JINGLE_DURATION, END_BLACK_SCREEN_DURATION, getProjectFileDuration, } from '../../../utils/creatomate';
import ProjectMusicUpload from './ProjectMusicUpload';
import useApiRequest from '../../../../../hooks/api-request';
import ProjectMusicSelection from './ProjectMusicSelection/ProjectMusicSelection';
import { MUSIC_DEFAULT_VALUE, MUSIC_DEFAULT_VOLUME, MUSIC_LIBRARY_IDS, MUSIC_PREVIEW_DURATION } from './ProjectMusicTab.utils';
import useAudioPlayer from '../../../../../hooks/audio-player';
import ProjectMusicLibrary from './ProjectMusicLibrary/ProjectMusicLibrary';
import VolumeFieldMusicProject from "../../../../common/VolumeField/VolumeFieldMusicProject";
import { GreenButton } from "../../../../common/styles/buttons.styles";
import { Close } from "@mui/icons-material";
import { ModalWrapper, SmallModalContent } from "../../../../common/styles/modals.styles";
import { useTranslation } from "react-i18next";
let musicPreviewTimout;
export default function ProjectMusicTab({ active, project, updateProject }) {
    const theme = useTheme();
    const [cookies] = useCookies();
    const API = useApiRequest();
    const [loadingFiles, setLoadingFiles] = useState([]);
    const [projectMusicFiles, setProjectMusicFiles] = useState([]);
    const [projectMusicVolume, setProjectMusicVolume] = useState(MUSIC_DEFAULT_VOLUME);
    const [finalVideoDuration, setFinalVideoDuration] = useState(0);
    const [playingMusicFile, setPlayingMusicFile] = useState();
    const { audioNode, createAudio, destroyAudio, playAudio, pauseAudio, setAudioVolume, isAudioPlaying } = useAudioPlayer();
    const [closeWithoutSaveModal, setCloseWithoutSaveModal] = useState(false);
    const [isUpdatingMusic, setIsUpdatingMusic] = useState(false);
    const user = cookies.user;
    const { t, i18n } = useTranslation(['home', 'main']);
    useEffect(() => {
        if (project.files) {
            const duration = project.files.reduce((total, file) => {
                return total + getProjectFileDuration(file);
            }, JINGLE_DURATION + END_BLACK_SCREEN_DURATION);
            setFinalVideoDuration(duration);
        }
    }, [project.files]);
    useEffect(() => {
        if (project.music) {
            setProjectMusicFiles(project.music.files);
            setProjectMusicVolume(typeof project.music?.volume === "number" ? project.music.volume : MUSIC_DEFAULT_VOLUME);
        }
    }, [project.music]);
    useEffect(() => {
        setAudioVolume(projectMusicVolume);
    }, [projectMusicVolume]);
    // Ensure audio stops when navigating away
    useEffect(() => {
        if (!active && isAudioPlaying) {
            destroyAudio();
        }
    }, [active, isAudioPlaying]);
    useEffect(() => {
        if (audioNode) {
            function endListener() {
                setPlayingMusicFile(undefined);
            }
            audioNode.addEventListener('ended', endListener);
            return () => {
                audioNode.removeEventListener('ended', endListener);
            };
        }
    }, [audioNode]);
    useEffect(() => {
        (async () => {
            if (playingMusicFile) {
                await createAudio(playingMusicFile.file_path, true);
                // for library music, stop after preview duration
                if (MUSIC_LIBRARY_IDS.includes(playingMusicFile.id)) {
                    await handleMusicStopAfterPreview();
                }
            }
            else if (isAudioPlaying) {
                await destroyAudio();
            }
        })();
    }, [playingMusicFile]);
    function updateProjectMusic(updates) {
        updateProject({
            music: Object.assign(MUSIC_DEFAULT_VALUE, project.music, updates)
        });
    }
    async function updateProjectMusicFiles(files) {
        if (project?.id) {
            try {
                const response = await API.updateProjectMusicFiles(project.id, files);
                setProjectMusicFiles(response.data.files);
                updateProjectMusic({ files: response.data.files });
            }
            catch (error) {
                console.log("Error while updating project music files", error);
            }
        }
    }
    function handleUploadQueueUpdate(files) {
        setLoadingFiles(files);
    }
    async function deleteProjectMusicFile(fileId) {
        if (project?.id) {
            try {
                const response = await API.deleteProjectMusicFile(project.id, fileId);
                setProjectMusicFiles(response.data.files);
                updateProjectMusic({ files: response.data.files });
                if (fileId === playingMusicFile?.id) {
                    setPlayingMusicFile(undefined);
                }
            }
            catch (error) {
                console.log("Error while updating project music files", error);
            }
        }
    }
    async function handleVolumeChange(volume) {
        if (project?.id) {
            try {
                const response = await API.updateProjectMusic(project.id, { volume });
                updateProjectMusic(response.data.music);
                setProjectMusicVolume(response.data.music.volume);
            }
            catch (error) {
                console.log("Error while updating project music volume", error);
            }
        }
    }
    async function handleMusicPlay(file) {
        if (!playingMusicFile || playingMusicFile.id !== file.id) {
            setPlayingMusicFile(file);
        }
        else if (playingMusicFile.id === file.id) {
            await playAudio();
        }
    }
    async function handleMusicStop() {
        await destroyAudio();
        setPlayingMusicFile(undefined);
    }
    async function handleMusicStopAfterPreview() {
        musicPreviewTimout = setTimeout(() => {
            handleMusicStop();
            clearTimeout(musicPreviewTimout);
        }, MUSIC_PREVIEW_DURATION);
    }
    function handleMusicFileAdd() {
        setCloseWithoutSaveModal(false);
    }
    function handleMusicFileAddFinal(file) {
        setProjectMusicFiles((state) => [...state, file]);
        updateProjectMusic({ files: [...projectMusicFiles, file] });
    }
    async function handleLibraryMusicAdd(item) {
        setCloseWithoutSaveModal(false);
        try {
            const file = {
                id: uuid(),
                type: 'audio',
                file_name: item.file_name,
                file_path: item.file_path,
                cover: item.cover,
                duration: item.duration,
                userId: user.id,
                userName: user.first_name,
            };
            await updateProjectMusicFiles([...projectMusicFiles, file]);
        }
        catch (e) {
            console.log("Error while adding music from library", e);
        }
    }
    async function handleProjectMusicLoopChange(event) {
        try {
            setIsUpdatingMusic(true);
            const checked = event.target.checked;
            await API.updateProjectMusic(project.id, { loop: checked });
            updateProjectMusic({ loop: checked });
        }
        catch (e) {
            console.log("Error while updating project music loop", e);
        }
        finally {
            setIsUpdatingMusic(false);
        }
    }
    if (!active) {
        return null;
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Box, { p: 1, children: [_jsx(ProjectMusicSelection, { loadingFiles: loadingFiles, projectMusicFiles: projectMusicFiles, playingMusicFileId: playingMusicFile?.id, finalVideoDuration: finalVideoDuration, readonly: readonly, isPlayingMusic: isAudioPlaying, onMusicPlay: handleMusicPlay, onMusicPause: pauseAudio, onUpdateFiles: updateProjectMusicFiles, onDeleteFile: deleteProjectMusicFile }), _jsx(Box, { px: 1, pb: 2, children: _jsx(FormControlLabel, { label: t("dashboard_projects.music_loop_label", { ns: ['main'] }), control: (_jsx(Checkbox, { checked: project?.music?.loop, indeterminate: isUpdatingMusic, disabled: isUpdatingMusic || readonly, onChange: handleProjectMusicLoopChange })), slotProps: {
                        typography: {
                            variant: 'body2',
                        }
                    } }) }), _jsxs(Box, { px: 1, py: 2, children: [_jsx(Typography, { children: "Volume" }), _jsx(VolumeFieldMusicProject, { volume: projectMusicVolume, disabled: readonly || projectMusicFiles.length === 0, onChange: setProjectMusicVolume, onChangeCommitted: handleVolumeChange })] }), !readonly && (_jsx(_Fragment, { children: _jsx(Box, { py: 2, textAlign: "center", children: _jsx(GreenButton, { onClick: () => {
                            setCloseWithoutSaveModal(true);
                        }, variant: "contained", children: t("dashboard_projects.add_music", { ns: ['main'] }) }) }) })), !readonly && (_jsx(_Fragment, {})), _jsx(Modal, { BackdropProps: { style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }, open: closeWithoutSaveModal, style: {
                    position: 'absolute',
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "auto",
                    marginBottom: "auto",
                    top: 0,
                    bottom: 0,
                    borderRadius: 8,
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    // top:'10%',
                    // left:'10%',
                    overflow: 'scroll',
                    height: '85%',
                    width: '80%',
                    display: 'block'
                }, children: _jsxs("div", { style: { paddingLeft: "2%", paddingRight: "2%", backgroundColor: theme.palette.primary.main }, children: [_jsx("div", { style: { position: 'relative', top: 45, textAlign: 'right', }, children: _jsx(IconButton, { sx: { textAlign: 'right' }, size: "small", color: "default", onClick: () => {
                                    setCloseWithoutSaveModal(false);
                                }, children: _jsx(Close, { sx: { color: 'white' } }) }) }), _jsx(ProjectMusicLibrary, { playingMusicFileId: playingMusicFile?.id, onMusicPlay: handleMusicPlay, onMusicPause: handleMusicStop, onMusicAdd: handleLibraryMusicAdd }), _jsx(ProjectMusicUpload, { project: project, onMusicFileAdd: handleMusicFileAdd, onMusicFileAddFinal: handleMusicFileAddFinal, onUploadQueueUpdated: handleUploadQueueUpdate })] }) }), _jsx(Modal, { open: false, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", style: {
                    overflow: 'hidden'
                }, BackdropProps: { style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }, children: _jsx(ModalWrapper, { children: _jsxs(SmallModalContent, { children: [_jsx(ProjectMusicLibrary, { playingMusicFileId: playingMusicFile?.id, onMusicPlay: handleMusicPlay, onMusicPause: handleMusicStop, onMusicAdd: handleLibraryMusicAdd }), _jsx(ProjectMusicUpload, { project: project, onMusicFileAdd: handleMusicFileAdd, onMusicFileAddFinal: handleMusicFileAddFinal, onUploadQueueUpdated: handleUploadQueueUpdate }), _jsx(Box, { children: _jsx(Typography, { onClick: () => { setCloseWithoutSaveModal(false); }, variant: "body2", sx: { textDecorationLine: 'underline', cursor: "pointer", fontSize: 11, color: 'white', textAlign: "center" }, children: "Fermer" }) })] }) }) })] }));
}
