import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { PageSizes, PDFDocument } from 'pdf-lib';
import { CenteredContentBox, BoxText, BoxTitle } from './InviteQRCodePage.styles';
import { GreenButton } from '../../../common/styles/buttons.styles';
// @ts-ignore
import qrCodePdfInviteBackground from '../../../../../public/qr-code-pdf-invite-background.png';
// @ts-ignore
import qrCodePdfShareRectoBackground from '../../../../../public/qr-code-pdf-share-recto-background.png';
// @ts-ignore
import qrCodePdfShareVersoBackground from '../../../../../public/qr-code-pdf-share-verso-background.png';
import { useTranslation } from "react-i18next";
export default function QRCodePrint({ qrCode, isShare }) {
    const { projectId } = useParams();
    const { t, i18n } = useTranslation(['home', 'main']);
    async function downloadInviteQRCodePdf() {
        // Create PDF document
        const pdfDoc = await PDFDocument.create();
        // Fetch images
        const qrCodePdfBackgroundBytes = await fetch(qrCodePdfInviteBackground).then(res => res.arrayBuffer());
        const qrCodeBytes = await fetch(qrCode).then(res => res.arrayBuffer());
        // Embed images into pdf document, in order to draw them on the page
        const backgroundImage = await pdfDoc.embedPng(qrCodePdfBackgroundBytes);
        const qrCodeImage = await pdfDoc.embedPng(qrCodeBytes);
        // Create a page
        const page = pdfDoc.addPage();
        // Draw background image
        page.drawImage(backgroundImage, {
            x: 0,
            y: 0,
            width: page.getWidth(),
            height: page.getHeight(),
        });
        // Draw QR Code image
        page.drawImage(qrCodeImage, {
            x: page.getWidth() / 2 - qrCodeImage.width / 2,
            y: page.getHeight() / 3.075 - qrCodeImage.height / 2,
            width: qrCodeImage.width,
            height: qrCodeImage.height,
        });
        // Set PDF metadata
        const date = new Date();
        pdfDoc.setTitle("Mémorable - Invitation");
        pdfDoc.setSubject("Invitation à un projet de vidéo collective Mémorable");
        pdfDoc.setAuthor("Mémorable");
        pdfDoc.setKeywords(['memorable', 'qr-code', 'share', 'love', 'video', 'photo']);
        pdfDoc.setCreator("Mémorable (www.memorable.love)");
        pdfDoc.setCreationDate(date);
        pdfDoc.setModificationDate(date);
        // Save PDF document
        const pdfBytes = await pdfDoc.save();
        // Download PDF file
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `memorable-${projectId}-qr-code.pdf`;
        link.click();
    }
    async function downloadShareQRCodePdf() {
        // Create PDF document
        const pdfDoc = await PDFDocument.create();
        // Fetch images
        const qrCodePdfRectoBackgroundBytes = await fetch(qrCodePdfShareRectoBackground).then(res => res.arrayBuffer());
        const qrCodePdfVersoBackgroundBytes = await fetch(qrCodePdfShareVersoBackground).then(res => res.arrayBuffer());
        const qrCodeBytes = await fetch(qrCode).then(res => res.arrayBuffer());
        // Embed images into pdf document, in order to draw them on the page
        const rectoBackgroundImage = await pdfDoc.embedPng(qrCodePdfRectoBackgroundBytes);
        const versoBackgroundImage = await pdfDoc.embedPng(qrCodePdfVersoBackgroundBytes);
        const qrCodeImage = await pdfDoc.embedPng(qrCodeBytes);
        // Create a page
        let page = pdfDoc.addPage([PageSizes.A4[1], PageSizes.A4[0]]);
        // Draw recto background image
        page.drawImage(rectoBackgroundImage, {
            x: 0,
            y: 0,
            width: page.getWidth(),
            height: page.getHeight(),
        });
        page = pdfDoc.addPage([PageSizes.A4[1], PageSizes.A4[0]]);
        // Draw verso background image
        page.drawImage(versoBackgroundImage, {
            x: 0,
            y: 0,
            width: page.getWidth(),
            height: page.getHeight(),
        });
        // Draw QR Code image in verso page
        page.drawImage(qrCodeImage, {
            x: (page.getWidth() * 0.75) - qrCodeImage.width / 2,
            y: page.getHeight() / 2.25 - qrCodeImage.height / 2,
            width: qrCodeImage.width,
            height: qrCodeImage.height,
        });
        // Set PDF metadata
        const date = new Date();
        pdfDoc.setTitle("Mémorable - Vidéo finale");
        pdfDoc.setSubject("Visionner une vidéo collective Mémorable");
        pdfDoc.setAuthor("Mémorable");
        pdfDoc.setKeywords(['memorable', 'qr-code', 'share', 'love', 'video', 'photo']);
        pdfDoc.setCreator("Mémorable (www.memorable.love)");
        pdfDoc.setCreationDate(date);
        pdfDoc.setModificationDate(date);
        // Save PDF document
        const pdfBytes = await pdfDoc.save();
        // Download PDF file
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `memorable-${projectId}-qr-code.pdf`;
        link.click();
    }
    return (_jsxs(CenteredContentBox, { children: [_jsx(BoxTitle, { children: isShare ? t("qrcode_share.qrcode_share_display_title_offer_paper_card", { ns: ['main'] }) : t("qrcode_share.qrcode_share_display_title", { ns: ['main'] }) }), _jsx(BoxText, { children: isShare
                    ? t("qrcode_share.qrcode_share_display_descr_2", { ns: ['main'] })
                    : t("qrcode_share.qrcode_share_display_descr", { ns: ['main'] }) }), _jsx(GreenButton, { disabled: !qrCode, onClick: isShare ? downloadShareQRCodePdf : downloadInviteQRCodePdf, children: t("commons.print", { ns: ['main'] }) })] }));
}
