import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
export const ExportButton = styled(Button)(({ theme }) => ({
    width: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    textTransform: "none",
    fontFamily: "var(--main_font_family_primary)",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
        backgroundColor: theme.palette.grey[50],
        // color: theme.palette.secondary.contrastText,
    },
    "&.Mui-disabled": {
        color: theme.palette.grey[600],
        background: theme.palette.grey[100],
        boxShadow: "none",
        pointerEvents: "none",
        cursor: "default",
    }
}));
export const ExportButtonLabel = styled('span')(({ theme }) => ({
    display: "inline-block",
    marginLeft: theme.spacing(1),
}));
