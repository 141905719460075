import { getProjectContributorsCount } from './project';
/**
 * Order plans are based on contributors numbers:
 * Below 20 contributors: 14,9€
 * From 20 to 60 contributors: 35€
 * Above 61 contributors: 59€
 */
var PaymentPlanType;
(function (PaymentPlanType) {
    PaymentPlanType["Below20Contributors"] = "Below20";
    PaymentPlanType["From20To60Contributors"] = "From20To60";
    PaymentPlanType["Above60Contributors"] = "Above60";
})(PaymentPlanType || (PaymentPlanType = {}));
const PAYMENT_PLAN_PRICES = {
    [PaymentPlanType.Below20Contributors]: 14.9,
    [PaymentPlanType.From20To60Contributors]: 35,
    [PaymentPlanType.Above60Contributors]: 59,
};
const PAYMENT_PLAN_LABELS = {
    [PaymentPlanType.Below20Contributors]: 'Moins de 20 participants',
    [PaymentPlanType.From20To60Contributors]: 'De 20 à 60 participants',
    [PaymentPlanType.Above60Contributors]: '61 participants et plus',
};
export function getProjectPaymentPlan(project) {
    let type;
    const contributorsCount = getProjectContributorsCount(project);
    if (contributorsCount < 20) {
        type = PaymentPlanType.Below20Contributors;
    }
    else if (contributorsCount <= 60) {
        type = PaymentPlanType.From20To60Contributors;
    }
    else {
        type = PaymentPlanType.Above60Contributors;
    }
    return {
        label: PAYMENT_PLAN_LABELS[type],
        price: PAYMENT_PLAN_PRICES[type],
    };
}
