import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { GlobalLayout } from "../../pages/common/GlobalLayout";
import { routes } from "../../App";
export default function ContributorsRoutes() {
    const [cookies] = useCookies();
    const { projectId } = useParams();
    return Boolean(cookies.hasSeenContributorOnboarding)
        ? _jsx(GlobalLayout, {})
        : _jsx(Navigate, { to: `/${routes.onboarding}/${routes.contributor}/${projectId}/0` });
}
