import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
export const UploadFileInputContainer = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    backgroundColor: theme.palette.backgrounds.login,
    padding: theme.spacing(2),
    height: "15vh",
    minHeight: theme.spacing(15),
}));
export const UploadFileInputImage = styled('img')(({ theme }) => ({
    height: theme.spacing(5),
    marginRight: theme.spacing(4),
}));
export const UploadFileInputText = styled(Typography)(({ theme }) => ({
    fontStyle: "italic",
    fontFamily: "var(--main_font_family_secondary)",
}));
