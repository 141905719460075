import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Stack, useTheme } from '@mui/material';
import { SecondaryWhiteButton } from '../../../../../common/styles/buttons.styles';
import { ModalWrapper, ModalTitle, ModalCloseButton, SmallModalContent } from '../../../../../common/styles/modals.styles';
import { useTranslation } from "react-i18next";
export default function ProjectMediaDeleteModal({ media, onClose, onDelete }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation(['home', 'main']);
    if (!media) {
        return null;
    }
    return (_jsx(Modal, { open: !!media, onClose: onClose, "aria-labelledby": "supprimer le media d'un projet", "aria-describedby": "confirmation de la suppression du media", children: _jsx(ModalWrapper, { children: _jsxs(SmallModalContent, { display: "flex", flexDirection: "column", justifyContent: "center", children: [_jsx(ModalCloseButton, { onClick: onClose }), _jsx(ModalTitle, { sx: { pt: 0 }, children: t("commons.are_you_sure_delete_content", { ns: ['main'] }) }), _jsxs(Stack, { mt: 4, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: [_jsx(SecondaryWhiteButton, { onClick: onDelete, sx: {
                                    maxWidth: "none",
                                    background: theme.palette.common.white,
                                    color: theme.palette.secondary.main
                                }, children: t("commons.yes", { ns: ['main'] }) }), _jsx(SecondaryWhiteButton, { onClick: onClose, sx: {
                                    maxWidth: "none",
                                }, children: t("commons.no", { ns: ['main'] }) })] })] }) }) }));
}
