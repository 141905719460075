import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link, Alert, Box, Typography, Popover } from '@mui/material';
import { Stack } from "@mui/system";
import { LoginLoadingButton, LoginLogo, LoginPageWrapper, LoginTextField, LoginTitle, } from './styles/Login.styles';
import { useFormInput } from "../../utils/FormInput";
import { getRequestErrorMessage } from '../../utils/errors';
import useUser from '../../hooks/user';
import useApiRequest from '../../hooks/api-request';
import { routes } from "../../App";
import PasswordResetModal from './PasswordResetModal';
import PasswordField from './PasswordField';
// @ts-ignore
import logo_black from "../../../public/logo_black.svg";
import { useTranslation } from "react-i18next";
import '../../lang/i18n';
import { STORAGE_KEY_GENERAL_KEY, STORAGE_KEY_MEMORABLE_LANGUAGE } from "../../utils/storageKeys";
export default function Login() {
    const navigate = useNavigate();
    const email = useFormInput("");
    const password = useFormInput("");
    const API = useApiRequest();
    const { setUserSession } = useUser();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
    const [isPopoverLangOpen, setisPopoverLangOpen] = useState(false);
    const { t, i18n } = useTranslation(['home', 'main']);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const idPopover = 'simple-popover';
    const handleClosePopover = () => {
        setAnchorEl1(null);
        setisPopoverLangOpen(false);
    };
    const onClickLanguageChange = (lang) => {
        i18n.changeLanguage(lang); //change the language
        if (lang != undefined && lang != null && (lang == 'en' || lang == 'fr')) {
            localStorage.setItem(STORAGE_KEY_GENERAL_KEY + STORAGE_KEY_MEMORABLE_LANGUAGE, lang);
        }
    };
    async function handleLogin() {
        if (loading) {
            return;
        }
        if (!email.value || !password.value) {
            setError("Email ou mot de passe non renseigné.");
            return;
        }
        try {
            setError(null);
            setLoading(true);
            const { data } = await API.organizerLogin(email.value, password.value);
            setUserSession(data.user, data.token, "organizer");
            navigate(`/${routes.user}/${routes.messages}`);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
        finally {
            setLoading(false);
        }
    }
    function openPasswordResetModal() {
        setPasswordResetModalOpen(true);
    }
    function closePasswordResetModal() {
        setPasswordResetModalOpen(false);
    }
    return (_jsxs(LoginPageWrapper, { maxWidth: false, children: [_jsxs("div", { style: {
                    position: 'absolute',
                    right: 0,
                    marginRight: "1%",
                    top: 28,
                }, children: [_jsx("img", { onClick: () => {
                            setisPopoverLangOpen(true);
                        }, style: { width: 40 }, alt: "United States", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }, children: [_jsx(Typography, { onClick: () => {
                                    onClickLanguageChange('en');
                                    setisPopoverLangOpen(false);
                                }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                    onClickLanguageChange('fr');
                                    setisPopoverLangOpen(false);
                                }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), _jsx(LoginLogo, { src: logo_black }), _jsx(LoginTitle, { children: t("login.signin", { ns: ['main'] }) }), _jsx(Box, { width: { xs: "90%", md: "50%" }, margin: "0 auto", children: _jsxs(Stack, { display: "flex", alignItems: "stretch", spacing: 2, children: [_jsx(LoginTextField, { id: "email", label: t("commons.mailAddress", { ns: ['main'] }), variant: "standard", type: "email", ...email }), _jsxs(Box, { textAlign: "left", children: [_jsx(PasswordField, { label: t("commons.password", { ns: ['main'] }), ...password }), _jsx(Typography, { onClick: openPasswordResetModal, mt: 2, variant: "body2", display: "inline-block", sx: {
                                        "&:hover": {
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                    }, children: t("forget_password.forget_password_title", { ns: ['main'] }) })] }), error && (_jsx(Alert, { severity: "error", color: "info", children: error })), _jsx(Box, { px: 2, p: 2, children: _jsxs(Typography, { variant: "body2", textAlign: "center", children: [_jsx("span", { children: t("login.no_account_yet", { ns: ['main'] }) }), _jsx(Link, { component: RouterLink, sx: { color: "black", ml: 0.5 }, to: "/signup", children: t("login.signup", { ns: ['main'] }) })] }) }), _jsx(Box, { pt: 2, children: _jsx(LoginLoadingButton, { variant: "contained", sx: { cursor: "pointer", maxWidth: "none" }, loading: loading, onClick: handleLogin, children: t("login.connexion", { ns: ['main'] }) }) })] }) }), _jsx(PasswordResetModal, { open: passwordResetModalOpen, onClose: closePasswordResetModal })] }));
}
