import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, Popover, Typography } from '@mui/material';
import { theme } from "../../../utils/theme";
import GenericModal from "../../common/GenericModal";
import { Archive, VideoCameraBack } from "@mui/icons-material";
import useApiRequest from "../../../hooks/api-request";
// @ts-ignore
import download from "../../../../public/download.svg";
export default function DownloadButton({ project }) {
    const [isDownloading, setIsDownloading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation(['home', 'main']);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const API = useApiRequest();
    async function handleDownload() {
        try {
            setIsDownloading(true);
            const response = await axios({
                url: project?.finalVideo,
                method: "GET",
                responseType: "blob",
            });
            const urlObject = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = urlObject;
            link.download = `${project.id}.mp4`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloading(false);
        }
        catch (e) {
            setIsDownloading(false);
            console.error("Error while downloading final video", e);
        }
    }
    async function handleDownloadChunks() {
        try {
            setIsDownloading(true);
            const responseChunksZip = await API.getProjectContentChunksZip(project.id);
            const responseChunksZipPath = responseChunksZip.data.projectFiles;
            // console.log(finalTesla);
            // return;
            const link = document.createElement("a");
            link.href = responseChunksZipPath;
            link.download = `${project.id}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloading(false);
        }
        catch (e) {
            setIsDownloading(false);
            console.error("Error while downloading final chunks zip", e);
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (_jsxs(Box, { textAlign: "center", width: 100, children: [_jsx(IconButton, { sx: { height: "fit-content", mb: 1, p: 0 }, 
                //onClick={handleDownload}
                onClick: handleClick, children: _jsx("img", { src: download, alt: "download", style: {
                        width: theme.typography.pxToRem(35),
                        height: theme.typography.pxToRem(35),
                        color: theme.palette.primary.main,
                    } }) }), _jsx(Typography, { fontSize: 12, children: t("commons.download", { ns: ['main'] }) }), _jsxs(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, children: [_jsxs(Box, { onClick: handleDownload, style: {
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            flexDirection: 'row'
                        }, children: ["\u00A0\u00A0\u00A0", _jsx(VideoCameraBack, { style: {
                                    color: theme.palette.primary.main,
                                } }), _jsx(Typography, { sx: { p: 2 }, children: t("well_done.group_video", { ns: ['main'] }) })] }), _jsx(Divider, {}), _jsxs(Box, { onClick: handleDownloadChunks, style: {
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            flexDirection: 'row'
                        }, children: ["\u00A0\u00A0\u00A0", _jsx(Archive, { style: {
                                    color: theme.palette.primary.main,
                                } }), _jsx(Typography, { sx: { p: 2 }, children: t("well_done.seperate_content", { ns: ['main'] }) })] })] }), _jsx(GenericModal, { open: isDownloading, title: t("well_done.dont_leave", { ns: ['main'] }) })] }));
}
