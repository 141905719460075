import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Stack } from '@mui/material';
import { SecondaryWhiteButton } from '../../common/styles/buttons.styles';
import { ModalWrapper, ModalTitle, ModalCloseButton, SmallModalContent } from '../../common/styles/modals.styles';
export default function UploadErrorModal({ open, error, onClose }) {
    if (!error) {
        return null;
    }
    return (_jsx(Modal, { open: open, onClose: onClose, "aria-labelledby": "upload de contenu", "aria-describedby": "message d'erreur lors du t\u00E9l\u00E9chargement de contenu", children: _jsx(ModalWrapper, { children: _jsxs(SmallModalContent, { display: "flex", flexDirection: "column", justifyContent: "center", children: [_jsx(ModalCloseButton, { onClick: onClose }), _jsx(ModalTitle, { sx: { pt: 0 }, children: error }), _jsx(Stack, { mt: 4, direction: { xs: "column", sm: "row-reverse" }, spacing: 2, alignItems: { xs: "stretch", sm: "center" }, justifyContent: "center", children: _jsx(SecondaryWhiteButton, { onClick: onClose, sx: {
                                maxWidth: "none",
                            }, children: "Fermer" }) })] }) }) }));
}
