import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
// @ts-ignore
import backgroundUrl from "../../../../public/memorable_m_white.svg";
const DESKTOP_X_PADDING = 4;
const DESKTOP_Y_PADDING = 4;
const MOBILE_X_PADDING = 3;
const MOBILE_Y_PADDING = 3;
export const ModalWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
    },
}));
export const ModalContent = styled(Box)(({ theme }) => ({
    minHeight: "40vh",
    maxWidth: `calc(100% - ${theme.spacing(2 * DESKTOP_X_PADDING)})`,
    maxHeight: `calc(100% - ${theme.spacing(2 * DESKTOP_Y_PADDING)})`,
    position: "relative",
    padding: theme.spacing(DESKTOP_Y_PADDING, DESKTOP_X_PADDING),
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${backgroundUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    backgroundSize: "60%",
    [theme.breakpoints.down("sm")]: {
        maxWidth: `calc(100% - ${theme.spacing(2 * MOBILE_X_PADDING)})`,
        maxHeight: `calc(100% - ${theme.spacing(2 * MOBILE_Y_PADDING)})`,
        padding: theme.spacing(MOBILE_Y_PADDING, MOBILE_X_PADDING),
    },
}));
export const SmallModalContent = styled(ModalContent)(({ theme }) => ({
    minHeight: "25vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
}));
export const ModalTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "var(--main_font_family_secondary)",
    lineBreak: "normal",
    fontSize: "1.5rem",
    fontStyle: "italic",
    color: theme.palette.common.white,
    textAlign: "center",
    padding: theme.spacing(2, 2, 0),
}));
export const ModalText = styled(Typography)(({ theme }) => ({
    fontFamily: "var(--main_font_family_main)",
    lineBreak: "normal",
    fontSize: "1rem",
    color: theme.palette.common.white,
    textAlign: "center",
}));
export const ModalCloseButton = styled((props) => (_jsx(IconButton, { ...props, children: _jsx(Close, {}) })))(({ theme }) => ({
    color: theme.palette.common.white,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    "&:hover": {
        color: theme.palette.secondary.contrastText,
    },
}));
