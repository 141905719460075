import { styled } from "@mui/material/styles";
import { Divider as MUIDivider } from '@mui/material';
export const Divider = styled(MUIDivider)(({ theme }) => ({
    '&::before': {
        borderColor: theme.palette.primary.main,
        borderWidth: 'initial'
    },
    '&::after': {
        borderColor: theme.palette.primary.main,
        borderWidth: 'initial'
    }
}));
