import { ProjectStatus } from '../models/project';
/**
 * Get the number of contributors of a project
 * A uniq contributor is identified by his userId in the project files
 * @param project
 * @returns number
 */
export function getProjectContributorsCount(project) {
    return project.files && project.files.length > 0 ? Array.from(new Set(project.files.map((file) => file.userId))).length : 0;
}
/**
 * Determine if a project is editable
 * @param project
 */
export function isProjectEditable(project) {
    return project.status === ProjectStatus.ACTIVE;
}
