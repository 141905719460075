import { styled } from "@mui/material/styles";
import { BlockBox, BlockBoxTitle, BlockBoxText } from '../../../common/styles/boxes.styles';
export const CenteredContentBox = styled(BlockBox)(({ theme }) => ({
    textAlign: "center",
}));
export const BoxTitle = styled(BlockBoxTitle)(({ theme }) => ({
    fontSize: "1.4rem",
    textAlign: "center",
}));
export const BoxText = styled(BlockBoxText)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));
export const QRCodeImage = styled('img')(({ theme }) => ({
    maxWidth: "100%",
}));
