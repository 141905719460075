import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppBar, Box, Grid, Popover, Toolbar, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { LoggedUser } from "./LoggedUser";
import { routes } from "../../App";
import history from 'history/browser';
// @ts-ignore
import backgroundImage from "../../../public/background_header.png";
// @ts-ignore
import logo_white from "../../../public/logo_white.svg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { STORAGE_KEY_GENERAL_KEY, STORAGE_KEY_MEMORABLE_LANGUAGE } from "../../utils/storageKeys";
import useUser from '../../hooks/user';
import { UserRole } from '../../models/user';
export const LogoHeader = styled("img")(({ theme }) => ({
    cursor: "pointer",
    maxWidth: "200px",
    ["@media (max-width:960px)"]: {
        maxWidth: "50%",
    },
}));
export default function LayoutHeader() {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const path = useLocation();
    const { i18n } = useTranslation(['home', 'main']);
    const { role, isOrganizer, isContributor } = useUser();
    const withBackArrow = path.pathname !== `/${routes.user}/${routes.messages}` && !(projectId && path.pathname === `/${routes.contributor}/${routes.messages}/${projectId}`);
    const [isPopoverLangOpen, setisPopoverLangOpen] = useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const idPopover = 'simple-popover';
    const handleClosePopover = () => {
        setAnchorEl1(null);
        setisPopoverLangOpen(false);
    };
    const onClickLanguageChange = (lang) => {
        i18n.changeLanguage(lang); //change the language
        if (["en", "fr"].includes(lang)) {
            localStorage.setItem(STORAGE_KEY_GENERAL_KEY + STORAGE_KEY_MEMORABLE_LANGUAGE, lang);
        }
    };
    function handleBackNavigation() {
        // console.log(path)
        // return console.log("LA!!!!!!!!!!")
        let nextPath = -1;
        switch (role) {
            case UserRole.Organizer: {
                // Go back to projects list when we are on project overview page
                if (path.pathname.endsWith(`/${routes.messages}/${projectId}`)) {
                    nextPath = `/${routes.user}/${routes.messages}`;
                }
                // When editing project media, go back to project dashboard
                if (path.pathname.endsWith('texte') || path.pathname.endsWith('volume') || path.pathname.endsWith('raccourcir')) {
                    nextPath = `/${routes.user}/${routes.messages}/${projectId}`;
                }
                // When just created we don't want to go back to creation form
                if (path.pathname.endsWith('invite') || path.pathname.endsWith('merci') || path.pathname.endsWith('video-finale')) {
                    nextPath = `/${routes.user}/${routes.messages}/${projectId}`;
                }
                break;
            }
            case UserRole.Contributor: {
                if (projectId) {
                    nextPath = `/${routes.contributor}/${routes.messages}/${projectId}`;
                }
                break;
            }
            default:
                break;
        }
        // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
        if (path.pathname.includes('/raccourcir') || path.pathname.includes('/volume') || path.pathname.includes('/texte')) {
            try {
                history.push(nextPath);
            }
            catch (e) {
                console.log("ERROR BLOCKING");
            }
        }
        else {
            navigate(nextPath);
        }
    }
    return (_jsx(AppBar, { color: "primary", position: "static", sx: { backgroundImage: `url(${backgroundImage})` }, children: _jsxs(Toolbar, { className: "flex justify-between", sx: {
                justifyContent: "space-between",
                width: "90%",
                margin: "auto",
                p: 1,
            }, children: [_jsx(Grid, { sx: { width: "34px" }, children: withBackArrow && (_jsx(ArrowBackIos, { className: "cursor-pointer", style: { height: 32, padding: 1, cursor: "pointer" }, onClick: handleBackNavigation })) }), _jsx(LogoHeader, { src: logo_white, onClick: () => {
                        // TODO OTHER EDIT
                        if (isOrganizer()) {
                            if (path.pathname.includes('/raccourcir') || path.pathname.includes('/volume') || path.pathname.includes('/texte')) {
                                console.log("CCCC");
                                try {
                                    history.push(`${routes.messages}`);
                                }
                                catch (e) {
                                    console.log("ERROR BLOCKING");
                                }
                            }
                            else {
                                console.log("DDD");
                                navigate(`${routes.messages}`);
                            }
                        }
                    } }), _jsxs(Box, { className: "flex items-center", style: {
                        display: 'flex',
                        flexDirection: 'row'
                    }, children: [!isContributor() && _jsx(LoggedUser, {}), _jsxs("div", { style: {
                                marginTop: 12,
                                marginLeft: 12,
                                // display: "flex",
                                // position: 'absolute',
                                // right: 0,
                                // top: 0,
                                // marginRight:"1%",
                                // marginTop:"1.5%",
                            }, children: [_jsx("img", { onClick: () => {
                                        setisPopoverLangOpen(true);
                                    }, style: { width: 40 }, alt: "United States", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }, children: [_jsx(Typography, { onClick: () => {
                                                onClickLanguageChange('en');
                                                setisPopoverLangOpen(false);
                                            }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                                onClickLanguageChange('fr');
                                                setisPopoverLangOpen(false);
                                            }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), isContributor() && _jsx(Grid, { sx: { width: "34px" } })] })] }) }));
}
