import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
export const Logo = styled("img")(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "300px",
    ["@media (max-width:960px)"]: {
        width: "60%",
    },
    ["@media (min-width:960px)"]: {
        width: "40%",
    },
}));
export const ThanksMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: "2rem",
    fontFamily: "var(--main_font_family_secondary)",
    fontStyle: "italic",
    textAlign: "center",
    // padding: theme.spacing(2, 0),
}));
export const CheckImage = styled("img")(({ theme }) => ({
    width: theme.spacing(10),
}));
