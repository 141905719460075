import { styled } from '@mui/material/styles';
import { getMediaPreviewCommonSx } from '../../common/styles/media-previews';
export const Video = styled('video', {
    shouldForwardProp: (prop) => prop !== "orientation",
})(({ orientation }) => ({
    ...getMediaPreviewCommonSx(orientation),
}));
export const ImageContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== "orientation",
})(({ orientation }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...getMediaPreviewCommonSx(orientation),
}));
export const ImageWithinContainer = styled('img')(({ theme }) => ({
    maxWidth: "100%",
    maxHeight: "100%",
}));
