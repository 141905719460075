import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import history from "history/browser";
import { Box, CircularProgress } from '@mui/material';
import useApiRequest from '../../../../hooks/api-request';
import { theme } from '../../../../utils/theme';
import { Page, PageTitle } from '../../../common/styles/page.styles';
import { MediaPreview } from '../MediaPreview';
import MediaTextEditor from './MediaTextEditor';
import MediaTextActions from './MediaTextActions';
import { getMediaTextDefaultValue } from './MediaEditTextPage.utils';
import { routes } from '../../../../App';
import CreatomateMediaPreview from '../../../common/CreatomateMediaPreview/CreatomateMediaPreview';
import GenericModal from "../../../common/GenericModal";
export default function MediaEditTextPage() {
    const { t } = useTranslation(['main']);
    const { projectId, mediaId } = useParams();
    const navigate = useNavigate();
    const API = useApiRequest();
    const [project, setProject] = useState();
    const [media, setMedia] = useState();
    const [isMediaLoaded, setMediaLoaded] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [pathToGo, setPathToGo] = useState("/organisateur/message");
    const [closeWithoutSaveModal, setCloseWithoutSaveModal] = useState(false);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    let unblock = () => { };
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Perform actions before the component unloads
            event.preventDefault();
            event.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    useEffect(() => {
        if (!hasChange && unblock != null) {
            unblock();
        }
        unblock = history.block((tx) => {
            setPathToGo(tx.location.pathname);
            return manageBlockingSave(tx.location.pathname);
        });
        return () => {
            unblock();
        };
    }, [hasChange]);
    // MANAGE BLOCKING ON CONTENT EDITING : if user not saved => block page
    function manageBlockingSave(pathname) {
        if (hasChange) {
            setCloseWithoutSaveModal(true);
        }
        else {
            navigate(pathname);
        }
    }
    useEffect(() => {
        (async () => {
            if (projectId && mediaId) {
                try {
                    const response = await API.getProject(projectId);
                    const project = response.data.project;
                    setProject(project);
                    if (project.files) {
                        const media = project.files.find((media) => media.id === mediaId);
                        setMedia(media);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [projectId, mediaId]);
    // Set default media value effect
    useEffect(() => {
        if (media && !media.text) {
            setMedia({
                ...media,
                text: getMediaTextDefaultValue(),
            });
        }
    }, [media]);
    async function updateMediaText(text) {
        setHasChange(true);
        if (projectId && media) {
            try {
                const updatedMedia = Object.assign({}, media, { text });
                // await API.updateProjectFile(id, media.id, updatedMedia);
                setMedia(updatedMedia);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    async function deleteMediaText() {
        setHasChange(true);
        if (projectId && media) {
            try {
                const updatedMedia = Object.assign({}, media, { text: undefined });
                await API.updateProjectFile(projectId, media.id, updatedMedia);
                navigate(`/${routes.user}/${routes.messages}/${projectId}/medias/${media.id}`);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    function handleMediaLoaded() {
        setMediaLoaded(true);
    }
    function startEditMode() {
        setEditMode(true);
    }
    async function validateChanges() {
        if (projectId && media) {
            try {
                await API.updateProjectFile(projectId, media.id, media);
                // setMedia(updatedMedia);
                setHasChange(false);
                setEditMode(false);
            }
            catch {
                console.error("Error while saving media");
            }
        }
    }
    if (!project || !media || !media?.text) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    const width = window.innerWidth;
    const height = window.innerHeight;
    return (_jsxs(Page, { maxWidth: false, children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(PageTitle, { children: media.userName }), editMode ? (_jsx(MediaTextEditor, { mediaText: media.text, isMediaLoaded: isMediaLoaded, orientation: project.orientation, onUpdate: updateMediaText, children: _jsx(MediaPreview, { media: media, orientation: project.orientation, onLoad: handleMediaLoaded }) })) : (_jsx(CreatomateMediaPreview, { media: media, orientation: project.orientation }))] }), _jsx(Box, { display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", py: 2, children: _jsx(_Fragment, { children: _jsx(MediaTextActions, { mediaText: media.text, isGreyed: !editMode, onUpdate: updateMediaText, onDelete: deleteMediaText, onActive: () => {
                            setEditMode(true);
                        }, onSave: validateChanges }) }) }), _jsx(GenericModal, { title: t("commons.unsaved_changes", { ns: ['main'] }), open: closeWithoutSaveModal, onConfirm: async () => {
                    if (hasChange) {
                        await validateChanges();
                        setHasChange(false);
                    }
                    setCloseWithoutSaveModal(false);
                    navigate(pathToGo);
                }, onCancel: () => {
                    setCloseWithoutSaveModal(false);
                    navigate(pathToGo);
                } })] }));
}
