import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { theme } from "./utils/theme";
import packageJson from '../package.json';
console.log(`Mémorable client v${packageJson.version}`);
Sentry.init({
    enabled: process.env.SENTRY_ENABLED === "true",
    dsn: process.env.SENTRY_DNS,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                /^https?:\/\/(www\.)?(app|dev|dev2)\.memorable\.love\//
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(BrowserRouter, { children: _jsx("div", { className: "content", children: _jsx(ThemeProvider, { theme: theme, children: _jsx(App, {}) }) }) }));
