import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { Stack } from "@mui/system";
import { Page, PageTitle } from "../../common/styles/page.styles";
import InviteEmailForm from './InviteEmailForm';
import ContributorsEmailsList from './ContributorsEmailsList';
import InviteLinks from './InviteLinks';
import { Divider } from './InviteContributorsPage.styles';
import { getRequestErrorMessage } from '../../../utils/errors';
import useApiRequest from '../../../hooks/api-request';
import { useTranslation } from "react-i18next";
function InviteContributorsPage() {
    const { projectId } = useParams();
    const API = useApiRequest();
    const [project, setProject] = useState();
    const [linkCopied, setLinkCopied] = useState(false);
    const [emails, setEmails] = useState([]);
    const [updated, setUpdated] = useState(true);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation(['home', 'main']);
    useEffect(() => {
        (async () => {
            await getProject();
        })();
    }, [projectId]);
    useEffect(() => {
        (async () => {
            if (updated) {
                await getInvites();
                await getProject();
                setUpdated(false);
            }
        })();
    }, [updated]);
    async function getProject() {
        try {
            if (projectId) {
                const response = await API.getProject(projectId);
                setProject(response.data.project);
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function getInvites() {
        try {
            if (projectId) {
                const response = await API.getProjectInviteEmails(projectId);
                setEmails(response.data.emails);
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function handleEmailsAdd(addedEmails) {
        setEmails([...new Set([...emails, ...addedEmails])]);
        await sendInvites();
    }
    async function deleteInvite(email) {
        try {
            if (projectId) {
                await API.deleteProjectInviteEmail(projectId, email);
                setUpdated(true);
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    async function sendInvites() {
        try {
            if (projectId) {
                await API.sendProjectInviteEmails(projectId);
                setUpdated(true);
            }
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: t("invitation.invite_participants", { ns: ['main'] }) }), linkCopied && _jsx(Alert, { severity: "success", children: t("commons.link_copied", { ns: ['main'] }) }), _jsx(InviteLinks, { project: project, onLinkCopied: setLinkCopied }), _jsx(Divider, { children: t("commons.or", { ns: ['main'] }) }), _jsx(InviteEmailForm, { onEmailsAdd: handleEmailsAdd, onEmailsError: setError }), _jsx(ContributorsEmailsList, { emails: emails, onDeleteInvite: deleteInvite }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) }))] }));
}
export default InviteContributorsPage;
