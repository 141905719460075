import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Alert, Box, TextField, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Link } from "@mui/material";
import { Stack } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller, useForm } from "react-hook-form";
import { isDate, isFuture } from "date-fns";
import { fr } from "date-fns/locale";
import { OrangeButton } from "../common/styles/buttons.styles";
import { routes } from "../../App";
import { INITIAL_DESCRIPTION } from "./constants/constants";
import InfoTooltip from "../common/tooltips/InfoTooltip";
import { Page, PageTitle } from '../common/styles/page.styles';
import useApiRequest from '../../hooks/api-request';
import { getRequestErrorMessage } from '../../utils/errors';
import { useTranslation } from "react-i18next";
import useUser from '../../hooks/user';
import { OrganizerAccountType } from '../../models/user';
function CreateMessage() {
    const [cgvChecked, setCgvChecked] = useState(false);
    const [currentPublicUse, setCurrentPublicUse] = useState("yes");
    const navigate = useNavigate();
    const API = useApiRequest();
    const { user } = useUser();
    const [error, setError] = useState(null);
    const { t } = useTranslation(['home', 'main']);
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            name: "",
            orientation: "portrait",
            account_type: null,
            professionalSocialDeno: "",
            professionalSiretNumber: "",
            professionalAddress: "",
            professionalManagerFullame: "",
            endDate: null,
            description: INITIAL_DESCRIPTION,
        },
    });
    async function onSubmit(values) {
        try {
            values.isProfessional = user.account_type === OrganizerAccountType.Enterprise;
            values.isPublicUse = currentPublicUse == "yes";
            const response = await API.createProject(values);
            navigate(`/${routes.user}/${routes.messages}/${response.data.project.id}`);
        }
        catch (error) {
            const message = getRequestErrorMessage(error);
            setError(message);
        }
    }
    const isEnterpriseAccountType = user.account_type === OrganizerAccountType.Enterprise;
    return (_jsxs(Page, { children: [_jsx(PageTitle, { children: t("create_projects.create_projects", { ns: ['main'] }) }), _jsx(Typography, { sx: { mb: 2, mt: 2 }, children: t("create_projects.description", { ns: ['main'] }) }), _jsxs("form", { className: "flex flex-col", onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.title", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "name", control: control, rules: {
                                    validate: (value) => value.trim().length > 0,
                                }, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "name", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsxs(Box, { sx: { textAlign: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.orientation", { ns: ['main'] }), " ", _jsx("span", { style: { color: "red" }, children: "*" }), _jsx(InfoTooltip, { children: _jsxs(Typography, { children: [t("create_projects.orientation_info_1", { ns: ['main'] }), _jsx("br", {}), t("create_projects.orientation_info_2", { ns: ['main'] }), _jsx("br", {}), t("create_projects.orientation_info_3", { ns: ['main'] })] }) })] }), _jsx(Controller, { name: "orientation", control: control, render: ({ field }) => (_jsx(FormControl, { required: true, children: _jsxs(RadioGroup, { row: true, "aria-label": "orientation", ...field, children: [_jsx(FormControlLabel, { value: "portrait", control: _jsx(Radio, {}), label: t("create_projects.orientation_portrait", { ns: ['main'] }) }), _jsx(FormControlLabel, { value: "paysage", control: _jsx(Radio, {}), label: t("create_projects.orientation_landscape", { ns: ['main'] }) })] }) })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.content_deadline", { ns: ['main'] }), " ", _jsx("span", { style: { color: "red" }, children: "*" }), _jsx(InfoTooltip, { children: _jsxs(Typography, { children: [t("create_projects.content_deadline_info", { ns: ['main'] }), " "] }) })] }), _jsx(Controller, { name: "endDate", control: control, rules: {
                                    required: true,
                                    validate: (value) => {
                                        return !!value && isDate(new Date(value)) && isFuture(new Date(value));
                                    },
                                }, render: ({ field }) => (_jsx(LocalizationProvider, { adapterLocale: fr, dateAdapter: AdapterDateFns, children: _jsx(DatePicker, { disablePast: true, format: "dd/MM/yyyy", sx: { width: "fit-content", display: "flex", mt: 1 }, label: "dd/mm/yyyy", slotProps: {
                                            textField: {
                                                ...(formState?.errors?.endDate && {
                                                    error: true,
                                                    helperText: t("create_projects.please_select_date", { ns: ['main'] }),
                                                }),
                                                required: true,
                                            },
                                        }, ...field }) })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", sx: { mb: 1 }, children: [t("create_projects.message_to_participants", { ns: ['main'] }), " "] }), _jsx(Controller, { name: "description", control: control, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, minRows: 2, multiline: true, id: "description", label: "", variant: "outlined", type: "text", ...field })) })] }), isEnterpriseAccountType && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { textAlign: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.content_privacy", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" }), _jsx(InfoTooltip, { children: _jsx(Typography, { children: t("create_projects.content_privacy_descr", { ns: ['main'] }) }) })] }), _jsx(Controller, { name: "isPublicUse", control: control, render: ({ field }) => (_jsx(FormControl, { required: true, children: _jsxs(RadioGroup, { row: true, "aria-label": "isPublicUse", onChange: e => {
                                                    setCurrentPublicUse(e.target.value === "yes" ? "yes" : "no");
                                                    field.onChange(e);
                                                }, children: [_jsx(FormControlLabel, { value: "yes", control: _jsx(Radio, {}), label: "Oui", checked: currentPublicUse == "yes" ? true : false }), _jsx(FormControlLabel, { value: "no", control: _jsx(Radio, {}), label: "Non", checked: currentPublicUse == "no" ? true : false })] }) })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.company_name", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "professionalSocialDeno", control: control, rules: {
                                            validate: (value) => value.trim().length > 0,
                                        }, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "professionalSocialDeno", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.immat", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "professionalSiretNumber", control: control, rules: {
                                            validate: (value) => value.trim().length > 0,
                                        }, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "professionalSiretNumber", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.head_office_address", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "professionalAddress", control: control, rules: {
                                            validate: (value) => value.trim().length > 0,
                                        }, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "professionalAddress", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsxs(Box, { sx: { alignItems: "left", mt: 3 }, children: [_jsxs(Typography, { textAlign: "left", children: [t("create_projects.legal_representative_name", { ns: ['main'] }), _jsx("span", { style: { color: "red" }, children: "*" })] }), _jsx(Controller, { name: "professionalManagerFullame", control: control, rules: {
                                            validate: (value) => value.trim().length > 0,
                                        }, render: ({ field }) => (_jsx(TextField, { sx: { width: "100%" }, id: "professionalManagerFullame", label: "", variant: "outlined", type: "text", ...field })) })] }), _jsx(Box, { sx: { alignItems: "left", mt: 3 }, children: _jsx(Typography, { variant: "body2", children: _jsxs("label", { htmlFor: "agreement", children: [_jsx("input", { type: "checkbox", id: "agreement", checked: cgvChecked, onChange: () => setCgvChecked(!cgvChecked) }), _jsxs("span", { children: ["\u2002 ", t("create_projects.cguA1", { ns: ['main'] }), " ", _jsx(Link, { target: "_blank", href: "https://www.memorable.love/cgv-cgu-conditions-utilisation", children: t("create_projects.cguA2", { ns: ['main'] }) }), t("create_projects.cguA3", { ns: ['main'] }), "*"] })] }) }) })] })), _jsx(Stack, { sx: { alignItems: "center", pt: 3 }, children: _jsxs(OrangeButton, { type: "submit", variant: "contained", disabled: !formState.isValid || formState.isSubmitting || (isEnterpriseAccountType && !cgvChecked), children: [t("commons.create", { ns: ['main'] }), " "] }) })] }), error && (_jsx(Stack, { sx: { p: 2 }, children: _jsx(Alert, { severity: "error", color: "info", children: error }) }))] }));
}
export default CreateMessage;
