import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContent, LayoutWrapper } from '../styles/layouts.styles';
import LayoutHeader from '../LayoutHeader';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ProjectEditNavigation from './ProjectEditNavigation';
export default function ProjectEditLayout() {
    return (_jsxs(LayoutWrapper, { children: [_jsx(LayoutHeader, {}), _jsxs(Box, { component: "main", sx: {
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto"
                }, children: [_jsx(LayoutContent, { sx: { flex: 1, overflow: "auto" }, children: _jsx(Outlet, {}) }), _jsx(ProjectEditNavigation, {})] })] }));
}
