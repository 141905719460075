export const MEDIA_TEXT_FONT_SIZES = [15, 25, 35, 45, 55];
export const MEDIA_TEXT_FONTS = [
    "Montserrat",
    "Source Code Pro",
    "EB Garamond",
    "Dancing Script",
    "Courier Prime",
    // "Comic Mono", // not found on Google fonts
    "Oswald",
    "Passion One"
];
export const MEDIA_TEXT_COLORS = [
    "#ffffff",
    "#737373",
    "#000000",
    "#FF5757",
    "#FF66C4",
    "#8C52FF",
    "#5CE1E6",
    "#38B6FF",
    "#004AAD",
    "#00BF63",
    "#FFDE59",
    "#FF914D",
];
const MEDIA_TEXT_DEFAULT_FONT_SIZE = MEDIA_TEXT_FONT_SIZES[2];
const MEDIA_TEXT_DEFAULT_WIDTH = 60;
export function getMediaTextDefaultValue() {
    return {
        width: MEDIA_TEXT_DEFAULT_WIDTH,
        height: 10,
        x: (100 - MEDIA_TEXT_DEFAULT_WIDTH) / 2,
        y: (100 - MEDIA_TEXT_DEFAULT_FONT_SIZE) / 2,
        text: "",
        textAlign: "center",
        textColor: "#000000",
        textBackgroundColor: "transparent",
        fontFamily: "Montserrat",
        fontSize: MEDIA_TEXT_DEFAULT_FONT_SIZE,
        fontStyle: "normal",
        fontSizeScaleFactor: 1,
    };
}
