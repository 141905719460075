import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ProjectStatus } from '../../../../../../models/project';
import useApiRequest from '../../../../../../hooks/api-request';
import useUser from '../../../../../../hooks/user';
import { getRequestErrorMessage } from '../../../../../../utils/errors';
import { ProjectMediaListItem } from './ProjectMediaListItem';
import ProjectMediaPreviewModal from './ProjectMediaPreviewModal';
import ProjectMediaDeleteModal from './ProjectMediaDeleteModal';
function ProjectMediaList({ project }) {
    const { projectId } = useParams();
    const theme = useTheme();
    const API = useApiRequest();
    const [medias, setMedias] = useState([]);
    const [previewMedia, setPreviewMedia] = useState();
    const [deleteMedia, setDeleteMedia] = useState();
    const { user, isSubscriptionBasedOrganizer } = useUser();
    const { t, i18n } = useTranslation(['home', 'main']);
    useEffect(() => {
        (async () => {
            if (projectId && user?.id) {
                try {
                    const response = await API.getProjectFiles(projectId);
                    setMedias(response.data.files);
                }
                catch {
                    console.log('Cannot get project files');
                }
            }
        })();
    }, [projectId]);
    // Modals
    function openPreviewModal(media) {
        setPreviewMedia(media);
    }
    function closePreviewModal() {
        setPreviewMedia(undefined);
    }
    async function deletePreviewMedia() {
        if (previewMedia) {
            await deleteFile(previewMedia.id);
            setPreviewMedia(undefined);
        }
    }
    function openDeleteModal(media) {
        setDeleteMedia(media);
    }
    function closeDeleteModal() {
        setDeleteMedia(undefined);
    }
    async function deleteDeleteMedia() {
        if (deleteMedia) {
            await deleteFile(deleteMedia.id);
            setDeleteMedia(undefined);
        }
    }
    async function deleteFile(fileId) {
        if (projectId && user?.id) {
            try {
                const response = await API.deleteProjectFile(projectId, fileId);
                setMedias(response.data.files);
            }
            catch (error) {
                const message = getRequestErrorMessage(error);
                console.log('Cannot delete project file', message);
            }
        }
    }
    async function saveProjectFilesOrder(medias) {
        if (projectId && user?.id) {
            try {
                const response = await API.updateProjectFilesOrder(projectId, medias);
                setMedias(response.data.files);
            }
            catch (error) {
                const message = getRequestErrorMessage(error);
                console.log('Cannot update project files order', message);
            }
        }
    }
    function reorder(startIndex, endIndex) {
        const result = Array.from(medias);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const updatedMedias = reorder(result.source.index, result.destination.index);
        return saveProjectFilesOrder(updatedMedias);
    }
    const readonly = project.status !== ProjectStatus.ACTIVE;
    return (_jsxs(Box, { children: [_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "medias", children: (provided) => (_jsxs(Box, { display: "flex", flexDirection: "column", ref: provided.innerRef, ...provided.droppableProps, children: [medias.map((media, index) => (_jsx(ProjectMediaListItem, { media: media, index: index, readonly: readonly && !isSubscriptionBasedOrganizer(), showDownloadButton: isSubscriptionBasedOrganizer(), onPreview: openPreviewModal }, media.id))), provided.placeholder] })) }) }), _jsx(ProjectMediaPreviewModal, { orientation: project.orientation, media: previewMedia, readonly: readonly && !isSubscriptionBasedOrganizer(), onClose: closePreviewModal, onDelete: deletePreviewMedia }), _jsx(ProjectMediaDeleteModal, { media: deleteMedia, onClose: closeDeleteModal, onDelete: deleteDeleteMedia }), (!medias || medias.length === 0) && (_jsx(Typography, { sx: { fontStyle: "italic", color: theme.palette.text.disabled, px: 1 }, children: t("commons.no_content_yet", { ns: ['main'] }) }))] }));
}
export default ProjectMediaList;
