import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ProjectContentContainer } from './ProjectContent.styles';
import ProjectContentMenu from './ProjectContentMenu/ProjectContentMenu';
import ProjectMediaTab from './ProjectMediaTab/ProjectMediaTab';
import ProjectMusicTab from './ProjectMusicTab/ProjectMusicTab';
export function ProjectContent({ project, updateProject }) {
    const [activeTab, setActiveTab] = React.useState("media");
    return (_jsxs(ProjectContentContainer, { children: [_jsx(ProjectContentMenu, { projectId: project.id, activeTab: activeTab, setActiveTab: setActiveTab }), _jsx(ProjectMediaTab, { active: activeTab === "media", project: project }), _jsx(ProjectMusicTab, { active: activeTab === "music", project: project, updateProject: updateProject })] }));
}
