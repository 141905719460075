import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { Page, PageTitle } from '../../common/styles/page.styles';
import { useProject } from '../../../contexts/project';
import { theme } from '../../../utils/theme';
import CreatomateMediaPreview from '../../common/CreatomateMediaPreview/CreatomateMediaPreview';
export default function MediaEditPage() {
    const { mediaId } = useParams();
    const { project, isProjectLoading } = useProject();
    const [media, setMedia] = useState();
    useEffect(() => {
        (async () => {
            if (project && mediaId) {
                try {
                    if (project.files) {
                        const media = project.files.find((media) => media.id === mediaId);
                        setMedia(media);
                    }
                }
                catch {
                    console.error("Error while fetching media");
                }
            }
        })();
    }, [project, mediaId]);
    if (isProjectLoading || !project || !media) {
        return (_jsx(Box, { sx: { mt: 4, textAlign: 'center' }, children: _jsx(CircularProgress, { sx: { color: theme.palette.secondary.main } }) }));
    }
    return (_jsx(Page, { sx: { display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }, children: _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(PageTitle, { children: media.userName }), _jsx(CreatomateMediaPreview, { media: media, orientation: project.orientation })] }) }));
}
