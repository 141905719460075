import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
// import { Slider } from '@mui/material';
import { formatDuration } from '../../../../utils/numbers';
import { StyledSlider } from './VideoDurationSlider.styles';
import { SliderThumb } from '@mui/material';
const MIN_DISTANCE = 2; // one second
function VideoSliderThumb(props) {
    const { children, ...other } = props;
    return (_jsxs(SliderThumb, { ...other, children: [children, _jsx("span", { className: "bar" })] }));
}
export default function VideoDurationSlider({ duration, startAt, endAt, onChange }) {
    const [value, setValue] = useState([startAt || 0, endAt || duration]);
    function handleChange(event, value, activeThumb) {
        if (!Array.isArray(value)) {
            return;
        }
        let [start, end] = value;
        if (activeThumb === 0) {
            start = Math.min(start, end - MIN_DISTANCE);
        }
        else {
            end = Math.max(end, start + MIN_DISTANCE);
        }
        setValue([start, end]);
    }
    function handleChangeCommitted(event, value) {
        if (!Array.isArray(value)) {
            return;
        }
        const [start, end] = value;
        onChange(start, end);
    }
    return (_jsx(StyledSlider, { size: "small", color: "secondary", slots: { thumb: VideoSliderThumb }, valueLabelDisplay: "auto", valueLabelFormat: (value, index) => `${index === 0 ? "Début" : "Fin"}: ${formatDuration(value)}`, min: 0, max: duration, step: 0.1, value: value, onChange: handleChange, onChangeCommitted: handleChangeCommitted, disableSwap: true }));
}
