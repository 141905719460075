import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Add, Home, Logout } from "@mui/icons-material";
import { routes } from "../../App";
import { getFilter } from "../../utils/theme";
// @ts-ignore
import accountLogo from "../../../public/account_logo.svg";
import useUser from '../../hooks/user';
import history from "history/browser";
import { useTranslation } from "react-i18next";
export { LoggedUser };
function LoggedUser({ color = "inherit" }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { removeUserSession } = useUser();
    const [cookies, setCookie, removeCookie] = useCookies();
    const path = useLocation();
    const { t, i18n } = useTranslation(['home', 'main']);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        removeUserSession();
        navigate(`/login`);
    };
    if (!cookies.user) {
        return (_jsx(Box, { width: "55px" }));
    }
    return (_jsxs("div", { children: [_jsx(IconButton, { size: "small", "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleMenu, color: color, sx: { transform: "scale(0.75)" }, children: _jsx("img", { src: accountLogo }) }), _jsxs(Menu, { id: "menu-appbar", anchorEl: anchorEl, anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, keepMounted: true, transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, open: Boolean(anchorEl), onClose: handleClose, sx: { mt: 4 }, children: [_jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => {
                            handleClose();
                            // TODO OTHER EDIT
                            if (path.pathname.includes('/raccourcir') || path.pathname.includes('/volume') || path.pathname.includes('/texte')) {
                                try {
                                    history.push(`/${routes.user}/${routes.messages}`);
                                }
                                catch (e) {
                                    console.log("ERROR BLOCKING");
                                }
                            }
                            else {
                                navigate(`/${routes.user}/${routes.messages}`);
                            }
                        }, children: [_jsx(Home, { sx: { width: "20px", color: theme.palette.primary.main } }), _jsx(Typography, { sx: { ml: 2 }, children: t("my_projects.my_projects", { ns: ['main'] }) })] }), _jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => {
                            handleClose();
                            if (path.pathname.includes('raccourcir')) {
                                try {
                                    history.push(`/${routes.user}/${routes.messages}/nouveau`);
                                }
                                catch (e) {
                                    console.log("ERROR BLOCKING");
                                }
                            }
                            else {
                                navigate(`/${routes.user}/${routes.messages}/nouveau`);
                            }
                        }, children: [_jsx(Add, { sx: { width: "20px", color: theme.palette.primary.main } }), _jsx(Typography, { sx: { ml: 2 }, children: t("commons.new_video", { ns: ['main'] }) })] }), _jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: () => {
                            handleClose();
                            if (path.pathname.includes('raccourcir')) {
                                try {
                                    history.push(`/${routes.user}/infos`);
                                }
                                catch (e) {
                                    console.log("ERROR BLOCKING");
                                }
                            }
                            else {
                                navigate(`/${routes.user}/infos`);
                            }
                        }, children: [_jsx("img", { src: accountLogo, style: { width: "20px", filter: getFilter("green") } }), _jsx(Typography, { sx: { ml: 2 }, children: t("edit_profil.edit_profil_title", { ns: ['main'] }) })] }), _jsxs(MenuItem, { sx: { cursor: "pointer" }, onClick: handleLogout, children: [_jsx(Logout, { sx: { width: "20px", color: theme.palette.primary.main } }), _jsx(Typography, { sx: { ml: 2 }, children: t("commons.logout", { ns: ['main'] }) })] })] })] }));
}
