import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { TabSectionTitle } from '../ProjectMusicTab.styles';
import { Box, Typography, useTheme } from '@mui/material';
import InfoTooltip from '../../../../../common/tooltips/InfoTooltip';
import ProjectMusicFilesList from './ProjectMusicFilesList';
import { formatAudioDuration, PROJECT_MUSIC_SELECTION_ID } from '../ProjectMusicTab.utils';
import ProjectMusicLoadingFiles from './ProjectMusicLoadingFiles';
import { useTranslation } from "react-i18next";
export default function ProjectMusicSelection({ loadingFiles, projectMusicFiles, playingMusicFileId, finalVideoDuration, isPlayingMusic, readonly, onMusicPlay, onMusicPause, onUpdateFiles, onDeleteFile }) {
    const theme = useTheme();
    const [musicDuration, setMusicDuration] = useState(0);
    const { t, i18n } = useTranslation(['home', 'main']);
    useEffect(() => {
        if (projectMusicFiles) {
            const duration = projectMusicFiles.reduce((total, file) => total + file.duration, 0);
            setMusicDuration(duration);
        }
    }, [projectMusicFiles]);
    return (_jsxs(Box, { id: PROJECT_MUSIC_SELECTION_ID, px: 1, py: 2, children: [_jsx(TabSectionTitle, { children: t("dashboard_projects.your_selection", { ns: ['main'] }) }), _jsx(ProjectMusicFilesList, { files: projectMusicFiles, playingFileId: playingMusicFileId, readonly: readonly, isPlayingMusic: isPlayingMusic, onMusicPlay: onMusicPlay, onMusicPause: onMusicPause, onUpdateFiles: onUpdateFiles, onDeleteFile: onDeleteFile }), _jsx(ProjectMusicLoadingFiles, { files: loadingFiles }), _jsxs(Box, { pt: 2, children: [_jsxs(Typography, { variant: "body2", sx: { color: finalVideoDuration > musicDuration ? theme.palette.secondary.main : theme.palette.common.black }, children: [t("dashboard_projects.total_music_duration", { ns: ['main'] }), ": ", formatAudioDuration(musicDuration), ".", _jsx(InfoTooltip, { children: _jsx("span", { children: t("dashboard_projects.music_info_1", { ns: ['main'] }) }) })] }), _jsxs(Typography, { variant: "body2", sx: { fontStyle: "italic" }, children: [t("dashboard_projects.current_video_duration", { ns: ['main'] }), ": ", formatAudioDuration(finalVideoDuration), "."] })] })] }));
}
