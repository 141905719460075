import { TableContainer, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
export const CartSummaryContainer = styled(TableContainer)(({ theme }) => ({
    width: "calc(100% - 4px)",
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.grey[50],
}));
export const CartSummaryRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    }
}));
export const CartSummaryCell = styled(TableCell)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(16),
}));
export const CartSummaryBoldCell = styled(CartSummaryCell)(({ theme }) => ({
    fontWeight: "bold",
}));
export const CartSummaryGreenCell = styled(CartSummaryCell)(({ theme }) => ({
    color: theme.palette.primary.main,
}));
