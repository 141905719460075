import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
export const ActionsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
}));
export const ActionsContainer = styled(Box)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(2)})`,
}));
