import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { Box, Popover, Typography } from '@mui/material';
import { routes } from "../../App";
import { OnboardingContainer, OnboardingImage, OnboardingLogo, OnboardingLogoPro, OnboardingNextButton, OnboardingNextButtonPro, OnboardingSkipButton, OnboardingStep, OnboardingStepNumber, OnboardingStepsContainer, OnboardingText, OnboardingTitle, OnboardingTitlePro, OnboardingWrapper } from './ContributorOnboarding.styles';
// @ts-ignore
import LOGO_BLANC from "./assets/img/LOGO_BLANC.png";
// @ts-ignore
import LOGO_GREEN from "./assets/img/LOGO_GREEN.png";
// @ts-ignore
import step1Image1Pro from "./assets/img/PARTICIPANT/ONBOARDING_PRO_STEP_1.png";
// @ts-ignore
import step1Image1 from "./assets/step-1-image-1.png";
// @ts-ignore
import step1Image2 from "./assets/step-1-image-2.png";
// @ts-ignore
import step2Image from "./assets/step-2-image.png";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { STORAGE_KEY_GENERAL_KEY, STORAGE_KEY_MEMORABLE_LANGUAGE } from "../../utils/storageKeys";
export default function ContributorOnboarding() {
    const navigate = useNavigate();
    const { projectId, step, isProfessional } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [height, setHeight] = useState(window?.innerHeight ?? 0);
    const [width, setWidth] = useState(window?.innerWidth ?? 0);
    const [isPopoverLangOpen, setisPopoverLangOpen] = useState(false);
    const { t, i18n } = useTranslation(['home', 'main']);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const idPopover = 'simple-popover';
    const handleClosePopover = () => {
        setAnchorEl1(null);
        setisPopoverLangOpen(false);
    };
    const onClickLanguageChange = (lang) => {
        i18n.changeLanguage(lang); //change the language
        if (lang != undefined && lang != null && (lang == 'en' || lang == 'fr')) {
            localStorage.setItem(STORAGE_KEY_GENERAL_KEY + STORAGE_KEY_MEMORABLE_LANGUAGE, lang);
        }
    };
    const onResize = useCallback(() => {
        setHeight(window?.innerHeight ?? height);
        setWidth(window?.innerWidth ?? width);
        //if (ref.current) setHeight(ref.current.clientHeight);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    function handleSkip() {
        setOnboardingSeenCookie();
        navigate(`/${routes.contributor}/${routes.messages}/${projectId}/login/${isProfessional}`);
    }
    function setOnboardingSeenCookie() {
        setCookie("hasSeenContributorOnboarding", true, {
            path: "/",
            maxAge: 86400 * 14,
        });
    }
    // @ts-ignore
    if (step === "0" && isProfessional == 1) {
        // const height = window.innerHeight;
        // const width = window.innerWidth;
        const topZoneHeight = height * 0.1;
        const middleZoneHeight = height * 0.6;
        const bottomZoneHeight = height * 0.3;
        return (_jsxs(Box, { style: {
                // flex:1,
                // height: height,
                backgroundColor: '#F9F1EC',
            }, children: [_jsxs("div", { style: {
                        position: 'absolute',
                        right: 20,
                        top: 28,
                    }, children: [_jsx("img", { onClick: () => {
                                setisPopoverLangOpen(true);
                            }, style: { width: 40 }, alt: "Language", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            }, children: [_jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('en');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('fr');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), _jsx(Box, { style: { height: topZoneHeight, display: 'flex' }, children: _jsx(OnboardingLogoPro, { alt: "logo", src: LOGO_GREEN }) }), _jsx(Box, { style: { height: middleZoneHeight, display: 'flex', justifyContent: 'center' }, children: _jsx("img", { style: { alignSelf: 'center', justifySelf: 'center', maxWidth: middleZoneHeight }, width: width, src: step1Image1Pro }) }), _jsxs(Box, { style: { height: bottomZoneHeight, display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(OnboardingTitlePro, { children: t("onboarding_participants.time_send", { ns: ['main'] }) }), _jsx(OnboardingNextButtonPro
                        // component={Link}
                        // to={`/${routes.onboarding}/${projectId}/1`}
                        , { 
                            // component={Link}
                            // to={`/${routes.onboarding}/${projectId}/1`}
                            onClick: handleSkip, children: t("onboarding_participants.lets_go", { ns: ['main'] }) })] })] }));
    }
    // @ts-ignore
    if (step === "0" && isProfessional != 1) {
        return (_jsxs(OnboardingWrapper, { step: step, children: [_jsxs("div", { style: {
                        position: 'absolute',
                        right: 20,
                        top: 28,
                    }, children: [_jsx("img", { onClick: () => {
                                setisPopoverLangOpen(true);
                            }, style: { width: 40 }, alt: "Language", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            }, children: [_jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('en');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('fr');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), _jsx(OnboardingSkipButton, { style: {
                        position: "absolute",
                        top: "20px",
                        right: "100px",
                    }, onClick: handleSkip, children: t("commons.skip", { ns: ['main'] }) }), _jsxs(OnboardingContainer, { children: [_jsx(OnboardingLogo, { alt: "logo", src: LOGO_BLANC }), _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingTitle, { children: t("onboarding_participants.onboarding_participants_title", { ns: ['main'] }) }), _jsx(OnboardingText, { sx: { mt: 2 }, children: t("onboarding_participants.onboarding_participants_title_2", { ns: ['main'] }) }), _jsx(OnboardingNextButton, { component: Link, to: `/${routes.onboarding}/${projectId}/1`, onClick: setOnboardingSeenCookie, children: t("commons.next", { ns: ['main'] }) })] })] })] }));
    }
    if (step === "1") {
        return (_jsxs(OnboardingWrapper, { step: step, children: [_jsxs("div", { style: {
                        position: 'absolute',
                        right: 20,
                        top: 28,
                    }, children: [_jsx("img", { onClick: () => {
                                setisPopoverLangOpen(true);
                            }, style: { width: 40 }, alt: "Language", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            }, children: [_jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('en');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('fr');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), _jsx(OnboardingSkipButton, { onClick: handleSkip, children: t("commons.skip", { ns: ['main'] }) }), _jsxs(OnboardingStepsContainer, { children: [_jsx(OnboardingStep, { active: true }), _jsx(OnboardingStep, { active: false })] }), _jsxs(OnboardingContainer, { children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingStepNumber, { children: "1" }), _jsx(OnboardingTitle, { children: t("onboarding_participants.send_content_title", { ns: ['main'] }) }), _jsx(OnboardingText, { sx: { mt: 4 }, children: t("onboarding_participants.send_content_descr_1", { ns: ['main'] }) })] }), _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", children: [_jsx(OnboardingImage, { alt: "step 1 image 1", sx: { maxWidth: "38%" }, src: step1Image1 }), _jsx(OnboardingImage, { alt: "step 1 image 2", sx: { maxWidth: "58%" }, src: step1Image2 })] }), _jsx(OnboardingNextButton, { component: Link, to: `/${routes.onboarding}/${projectId}/2`, children: t("commons.next", { ns: ['main'] }) })] })] })] }));
    }
    if (step === "2") {
        return (_jsxs(OnboardingWrapper, { step: step, children: [_jsxs("div", { style: {
                        position: 'absolute',
                        right: 20,
                        top: 28,
                    }, children: [_jsx("img", { onClick: () => {
                                setisPopoverLangOpen(true);
                            }, style: { width: 40 }, alt: "Language", src: i18n.language == "en" ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" : "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" }), _jsxs(Popover, { id: idPopover, open: isPopoverLangOpen, anchorEl: anchorEl1, onClose: handleClosePopover, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            }, children: [_jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('en');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "English" }), _jsx(Typography, { onClick: () => {
                                        onClickLanguageChange('fr');
                                        setisPopoverLangOpen(false);
                                    }, sx: { p: 2 }, children: "Fran\u00E7ais" })] })] }), _jsxs(OnboardingStepsContainer, { children: [_jsx(OnboardingStep, { active: true }), _jsx(OnboardingStep, { active: true })] }), _jsxs(OnboardingContainer, { children: [_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingStepNumber, { children: "2" }), _jsx(OnboardingTitle, { children: t("onboarding_participants.onboarding_participants_title_step_2", { ns: ['main'] }) }), _jsx(OnboardingText, { sx: { mt: 4 }, children: t("onboarding_participants.onboarding_participants_descr_1_step_2", { ns: ['main'] }) })] }), _jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", children: [_jsx(OnboardingImage, { alt: "step 2", src: step2Image }), _jsx(OnboardingNextButton, { component: Link, to: `/${routes.contributor}/${routes.messages}/${projectId}/login`, sx: { mt: 6 }, children: t("onboarding_participants.lets_go", { ns: ['main'] }) })] })] })] }));
    }
    return null;
}
