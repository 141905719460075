import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import useUser from '../../hooks/user';
import { routes } from '../../App';
export default function OrganizersRoutes() {
    const { user, isContributor, isOrganizer } = useUser();
    const { projectId } = useParams();
    // Redirect logged contributor trying to access organizer pages
    // Note: we use window.location.replace to prevent multiple redirect issue leading to wrong redirection issue
    if (!!user && isContributor()) {
        if (projectId) {
            window.location.replace(`/${routes.contributor}/${routes.messages}/${projectId}`);
        }
        else {
            window.location.replace(`/not-authorized`);
        }
    }
    return !!user && isOrganizer() ? _jsx(Outlet, {}) : _jsx(Navigate, { to: "/" });
}
