import { OrderStatus } from '../models/order';
/**
 * Get last order from orders array
 * @param orders
 */
export function getLastOrder(orders) {
    return !!orders.length ? orders[orders.length - 1] : null;
}
/**
 * Verify if order status is Paid
 * @param order
 */
export function isOrderPaid(order) {
    return order.status === OrderStatus.Paid;
}
/**
 * Compute order amount based on payment plan price and coupon
 * @param price
 * @param coupon
 */
export function getOrderAmount(price, coupon) {
    if (coupon?.percent_off) {
        return price - (price * coupon.percent_off / 100);
    }
    if (coupon?.amount_off) {
        return price - coupon.amount_off;
    }
    return price;
}
