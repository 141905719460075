import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { routes } from "../../App";
export default function OrganizersLoginRoutes() {
    const [cookies] = useCookies();
    const token = cookies.token;
    return !!token
        ? _jsx(Navigate, { to: `/${routes.user}/${routes.messages}` })
        : _jsx(Outlet, {});
}
