import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
export const MenuContainer = styled((props) => (_jsx(Box, { display: "flex", alignItems: "center", ...props })))(({ theme }) => ({
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    overflow: "hidden",
}));
export const MenuItem = styled(Typography)(({ theme }) => ({
    fontStyle: "italic",
    fontSize: "1.1rem",
    color: theme.palette.primary.main,
    textAlign: "center",
    fontFamily: "var(--main_font_family_secondary)",
    padding: theme.spacing(1),
    flex: 1,
    background: theme.palette.grey.A100,
    cursor: "pointer",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    [":first-of-type"]: {
        borderBottomLeftRadius: "0",
    },
    [":last-of-type"]: {
        borderBottomRightRadius: "0",
    },
    ["&.active"]: {
        background: theme.palette.common.white,
        cursor: "default",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
    }
}));
export const MenuLinksContainer = styled((props) => (_jsx(Box, { flex: 1, display: "flex", alignItems: "center", ...props })))(({ theme }) => ({
    background: theme.palette.grey.A100,
    borderBottomLeftRadius: "4px"
}));
export const MenuLinksDivider = styled((props) => (_jsx(Divider, { orientation: "vertical", flexItem: true, ...props })))(({ theme }) => ({
    alignSelf: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
}));
