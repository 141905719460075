import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { SwapVert, PlayCircleOutlined, PauseCircleOutlined } from '@mui/icons-material';
import { ProjectMusicFileListItemCover } from './ProjectMusicFileListItemCover';
import { formatAudioDuration } from '../ProjectMusicTab.utils';
// @ts-ignore
import trash from "../../../../../../../public/bin_orange.svg";
export function ProjectMusicFileListItem({ file, index, readonly, playing, onMusicPlay, onMusicPause, onDelete }) {
    const theme = useTheme();
    function deleteFile() {
        onDelete(file);
    }
    function playFileMusic() {
        onMusicPlay(file);
    }
    function pauseFileMusic() {
        onMusicPause();
    }
    return (_jsx(Draggable, { draggableId: file.id, index: index, isDragDisabled: readonly, children: (provided, snapshot) => (_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ref: provided.innerRef, ...provided.draggableProps, sx: {
                my: 1,
                p: 1.5,
                borderRadius: "8px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                background: snapshot.isDragging ? theme.palette.grey.A100 : "white"
            }, children: [_jsxs(Box, { flex: 1, display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(ProjectMusicFileListItemCover, { cover: file.cover }), _jsx(Box, { flex: 1, px: 2, children: _jsxs(Typography, { sx: { wordBreak: "break-word" }, children: [file.file_name, " - ", formatAudioDuration(file.duration)] }) })] }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(IconButton, { size: "small", color: "secondary", onClick: playing ? pauseFileMusic : playFileMusic, children: playing ? _jsx(PauseCircleOutlined, {}) : _jsx(PlayCircleOutlined, {}) }), !readonly && (_jsxs(_Fragment, { children: [_jsx(IconButton, { size: "small", color: "secondary", onClick: deleteFile, children: _jsx("img", { src: trash, alt: "delete", style: { width: "18px", height: "18px", padding: "3px" } }) }), _jsx(IconButton, { ...provided.dragHandleProps, size: "small", color: "secondary", children: _jsx(SwapVert, {}) })] }))] })] })) }, file.id));
}
